<template>
  <div class="shipments-wrapper__orders">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info">
          <span class="grid-heading">
            <h1>{{ $t('Shipments') }}</h1>
            <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <div class="label" style="width: 320px; height: 34px;" v-if="IsMainCompany()">
              <v-select v-model="quick.selected" :options="quick.options" :placeholder="$t('Send return label to shop printer')" @input="QuickShopReturn" @search:focus="LoadQuickShopPrinterStatus" :noDrop="quick.nodrop" :loading="quick.loading" :clearable="false">
                <template v-slot:selected-option="option">
                  <!--
                  <div class="truncate" style="display: flex;">
                    <span :class="['state', option.printnode_connected ? 'green' : 'red']" />
                    <span class="courier shop">{{ option.label }}</span>
                  </div>
                  -->
                  <div class="truncate">
                    <span :class="['state', option.printnode_connected ? 'green' : 'red']">{{ option.label }}</span>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <!--
                  <div class="truncate" style="display: flex;">
                    <span :class="['state', option.printnode_connected ? 'green' : 'red']" />
                    <span class="courier shop">{{ option.label }}</span>
                  </div>
                  -->
                  <div class="truncate">
                    <span :class="['state', option.printnode_connected ? 'green' : 'red']">{{ option.label }}</span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="label">
              <a class="button" style="min-width: 115px;" @click.prevent="OpenModal('create.shipment')" href="">{{ $t('Create') }}</a>
            </div>
          </div>
          <div class="grid-search">
            <input type="text" @keyup.enter="Search" v-model="search.query" :placeholder="$t('Search by shipment ID, tracking number, address etc...')" spellcheck="false">
          </div>
          <div class="grid-search-filter">
            <div class="grid-company" v-if="AllowCompanySearch()">
              <div class="select company">
                <div class="grid-heading">{{ $t('Company') }}</div>
                <v-select name="company" ref="company_select" @input="SearchByCompany" :value="company.options.find(option => option.id == search.company)" :options="company.options" />
              </div>
            </div>
            <div class="grid-shipping-country">
              <p class="grid-heading">{{ $t('Country') }}</p>
              <v-select name="label-shipping-country" @input="SearchByCountry" :value="country.options.find(option => option.id == search.country)" :options="Alphabetize(country.options.map(o => {o.label = CountryName(o.code); return o;}), 'label')">
                <template v-slot:selected-option="option">
                  <div class="truncate">
                    <Flag :code="option.code" size="small" />
                    <span>{{ option.label }}</span>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="truncate">
                    <Flag :code="option.code" size="small" />
                    <span>{{ option.label }}</span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="grid-shipping-agent">
              <p class="grid-heading">{{ $t('Delivery company') }}</p>
              <v-select name="label-shipping-agent" @input="SearchByShippingAgent" :value="shipping.option.agent.find(option => option.code == search.agent)" :options="shipping.option.agent">
                <template v-slot:selected-option="option">
                  <span :class="['courier', option.code]">
                    {{ option.label }}
                  </span>
                </template>
                <template slot="option" slot-scope="option">
                  <span :class="['courier', option.code]">
                    {{ option.label }}
                  </span>
                </template>
              </v-select>
            </div>
            <div class="grid-shipping-method">
              <p class="grid-heading">{{ $t('Shipping method') }}</p>
              <v-select name="label-shipping-method" @input="SearchByShippingMethod" :value="shipping.option.methods.find(option => option.code == search.method)" :options="shipping.option.method[shipping.agent && shipping.agent.code]">
                <template v-slot:selected-option="option">
                  <div class="truncate">
                    <span :class="['courier', shipping.agent && shipping.agent.code]">
                      {{ option.label }}
                    </span>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="truncate">
                    <span :class="['courier', shipping.agent && shipping.agent.code]">
                      {{ option.label }}
                    </span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="grid-shipment-create-date grid-date">
              <p class="grid-heading">{{ $t('Created') }}</p>
              <div class="date-range flex-row">
                <div class="input">
                  <VueCtkDateTimePicker @input="SearchByDate" id="search-date" v-model="date" :range="true" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :no-shortcuts="true" :no-button="true" :auto-close="true" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <table class="shipment-list table odd-even">
          <tbody>
            <tr :data-id="item.id" class="shipment-list__row clickable" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="item.id" v-for="item in shipments">
              <td class="shipment-id">
                <div class="flex-row">{{ item.id }}</div>
              </td>
              <td class="shipment-recipient">
                <div class="customer">
                  <span class="name">{{ item[`${item.type_code.includes('return') ? 'sender': 'customer'}_name`] }}</span>
                </div>
              </td>
              <td class="shipment-address">
                <div class="address-shipping">
                  <div class="recipient truncate">
                    <div class="flex-row customer">
                      <span class="address">{{ item[`${item.type_code.includes('return') ? 'sender': 'customer'}_address`] }}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td class="shipment-country">
                <div class="country-city">
                  <Flag :code="item.country.code" size="small" />
                  <div class="country" style="margin-left: 10px;">{{ CountryName(item.country.code) }}</div>
                  <!-- <div v-if="item.city" class="city" style="margin-left: 10px;">{{ item.city }}</div> -->
                </div>
              </td>
              <td class="shipping-agent trackingnumber">
                <!--
                <div class="shipping">
                  <span :class="['courier-icon', item.agent.code]" :title="item.agent.label" />
                  <span class="shipping-method">{{ item.method.label }}</span>
                </div>
                -->
                <div class="shipping" v-html="Hyperlink({target: '_blank', href: TrackingURL({agent: item.agent.code, code: item.tnt, tracking: false, company: item.res_company_id}), html: `<span class='courier-icon ${item.agent.code}' title='${item.agent.label}'></span><span class='shipping-method'>${item.method.label}</span>`, style: 'display: flex; align-items: center;'})" />
              </td>
              <td class="shipment-tracking">
                <div class="shipping tnt" v-html="Hyperlink({target: '_blank', href: TrackingURL({agent: item.agent.code, code: item.tnt, tracking: true, company: item.res_company_id}), text: item.tnt, style: 'display: inline-block;'})" />
              </td>
              <!--
              <td class="shipment-created">
                <div class="submition">
                  <span class="action">{{ TitleCase(item.action) }}</span>
                </div>
              </td>
              -->
              <td class="shipment-created">
                <div class="submition">
                  <span class="type">{{ item.type.map(word => /\{\{.*?\}\}/g.test(word) ? $t(word.replace(/[{}]/g, '')) : word).join('') }}</span>
                </div>
              </td>
              <td class="shipment-date">{{ DateFormat(item.create_date) }}</td>
              <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
                <a @click.prevent="" href="" class="icon dots">
                  <ul class="item-actions">
                    <li><a @click.prevent="OpenLabel(item.id)" href="">{{ $t('View details') }}</a></li>
                    <li><a @click.prevent="OpenPDF(item.id)" href="" class="success">{{ $t('Open PDF') }}</a></li>
                    <li><a @click.prevent="DownloadPDF(item)" href="" class="success">{{ $t('Download') }}</a></li>
                    <li><a @click.prevent="PrintPDF(item)" href="">{{ $t('Print') }}</a></li>
                    <li v-if="item.returnable">
                      <a class="actions-toggle" @click.prevent="" href="">
                        {{ $t('Create new') }}
                        <u class="nested-actions">
                          <li>
                            <a @click.prevent="OpenCreateShipmentFormModal(item)" href="">{{ $t('Shipping label') }}</a>
                          </li>
                          <li>
                            <a @click.prevent="OpenCreateShipmentFormModal(item, true)" href="">{{ $t('Return label') }}</a>
                          </li>
                        </u>
                      </a>
                    </li>
                    <li v-else><a @click.prevent="OpenCreateShipmentFormModal(item)" href="">{{ $t('Create new') }}</a></li>
                    <li v-if="item.agent.code == 'dao'">
                      <a @click.prevent="CancelShipment(item)" href="" class="error">{{ $t('Cancel') }}</a>
                    </li>
                  </ul>
                </a>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr class="shipment-list__actions">
              <th class="shipment-id">
                <a :class="[page.order.by == 'id' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('id')" href="">
                  <span>ID</span>
                </a>
              </th>
              <th class="shipment-recipient">
                <a :class="[page.order.by == 'customer_name' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('customer_name')" href="">
                  <span>{{ $t('Recipient') }}</span>
                </a>
              </th>
              <th class="shipment-address">
                <a :class="[page.order.by == 'customer_address' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('customer_address')" href="">
                  <span>{{ $t('Address') }}</span>
                </a>
              </th>
              <th class="shipment-destination">
                <a :class="[page.order.by == 'customer_res_country_id' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('customer_res_country_id')" href="">
                  <span>{{ $t('Country') }}</span>
                </a>
              </th>
              <th class="shipment-shipping">
                <a :class="[page.order.by == 'agent_code' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('agent_code')" href="">
                  <span>{{ $t('Shipping method') }}</span>
                </a>
              </th>
              <th class="shipment-tracking">
                <a :class="[page.order.by == 'tnt' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('tnt')" href="">
                  <span>{{ $t('Tracking number') }}</span>
                </a>
              </th>
              <th class="shipment-created">
                {{ $t('Type') }}
                <!--
                <a :class="[page.order.by == 'opcode' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('opcode')" href="">
                  <span>{{ $t('Type') }}</span>
                </a>
                -->
              </th>
              <th class="shipment-date">
                <a :class="[page.order.by == 'create_date' ? 'active' : '', page.order.direction]" @click.prevent="SetOrderBy('create_date')" href="">
                  <span>{{ $t('Created') }}</span>
                </a>
              </th>
              <th class="edit"></th>
            </tr>
          </thead>
        </table>
        <!--
        <div class="list-overflow-x" tabindex="0"></div>
        <div class="list-overflow-scrollbar bottom" style="bottom: 44px;">
          <div class="list-overflow-scrollbar-handle"></div>
        </div>
        -->
        <div class="grid-pagination shadow sticky bottom" :style="{marginTop: shipments.length && '2px'}">
          <div class="page-navigation">
            <div class="page-turn prev disabled" @click="PageController(false)"></div>
            <div class="page-number">
              <label class="page-number-current">
                <input type="number" min="1" :max="page.last" :value="page.current" @blur="PageNavigator" @keydown="PageNavigator">
                <span class="placeholder">{{NumberFormat(page.current)}}</span>
              </label>
              <span class="page-number-separator">/</span>
              <div class="page-number-last">{{NumberFormat(page.last)}}</div>
            </div>
            <div class="page-turn next" :class="{disabled: page.last == 1}" @click="PageController(true)"></div>
          </div>
        </div>

      </div>
    </div>

    <Modal modal="create.shipment" :value="modal.create.shipment.open" :title="$t('New shipment') + modal.create.shipment.setup.title">
      <div id="create-shipment">
        <div class="modal-header"></div>
        <div class="modal-content">
          <div class="modal-tabs">
            <ul class="modal-tabs__head">
              <li :class="['modal-tabs__head-item', {active: modal.create.shipment.tab.active == 0}]" :disabled="modal.create.shipment.tab.active != 0" @click="SetActiveModalTab('create.shipment', 0)">
                <span>{{ $t(modal.create.shipment.tab.page[0] && modal.create.shipment.tab.page[0].title || 'Delivery') }}</span>
              </li>
              <li v-for="(tab, index) in modal.create.shipment.tab.page.slice(1, modal.create.shipment.tab.page.length)" :key="index" :class="['modal-tabs__head-item', {active: modal.create.shipment.tab.active == index + 1}]" :disabled="modal.create.shipment.tab.active != index + 1/*!modal.create.shipment.tab.page[index + 1].clickable*/" @click="SetActiveModalTab('create.shipment', index + 1)">
                <span>{{ $t(tab.title) }}</span>
              </li>
            </ul>
            <ul class="modal-tabs__body">
              <li class="modal-tabs__body-content padding" v-show="modal.create.shipment.tab.active == 0">
                <div class="flex-group flex-column">
                  <div class="flex-row">
                    <div class="flex-column">
                      <div class="label">
                        <span class="label-text">
                          {{ $t('Sender country') }}
                        </span>
                        <v-select name="country_sender" ref="country_sender" :class="{'vs--loading': modal.create.shipment.country.select.sender.loading}" @input="HandleCreateShipmentCountry('sender')" v-model="modal.create.shipment.country.sender" :options="Alphabetize(modal.create.shipment.country.options.sender.map(o => {o.label = CountryName(o.code); return o;}), 'label')" :clearable="false">
                          <template slot="spinner">
                            <div class="vs__spinner" v-show="modal.create.shipment.country.select.sender.loading" />
                          </template>
                          <template v-slot:selected-option="option">
                            <div class="truncate">
                              <Flag :code="option.code" size="small" />
                              <span>{{ option.label }}</span>
                            </div>
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="truncate">
                              <Flag :code="option.code" size="small" />
                              <span>{{ option.label }}</span>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="flex-column">
                      <div class="label">
                        <span class="label-text">
                          {{ $t('Recipient country') }}
                        </span>
                        <v-select name="country_recipient" ref="country_recipient" :class="{'vs--loading': modal.create.shipment.country.select.recipient.loading}" @input="HandleCreateShipmentCountry('recipient')" v-model="modal.create.shipment.country.recipient" :options="Alphabetize(modal.create.shipment.country.options.recipient.map(o => {o.label = CountryName(o.code); return o;}), 'label')" :clearable="false">
                          <template slot="spinner">
                            <div class="vs__spinner" v-show="modal.create.shipment.country.select.recipient.loading" />
                          </template>
                          <template v-slot:selected-option="option">
                            <div class="truncate">
                              <Flag :code="option.code" size="small" />
                              <span>{{ option.label }}</span>
                            </div>
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="truncate">
                              <Flag :code="option.code" size="small" />
                              <span>{{ option.label }}</span>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="flex-column">
                      <div class="label">
                        <span class="label-text">
                          {{ $t('Delivery company') }}
                        </span>
                        <v-select name="shipping_agent" ref="shipping_agent" @input="ClearShipmentSetup(), FilterShippingMethods()" v-model="modal.create.shipment.shipping.agent" :options="modal.create.shipment.setup.option.agent" :clearable="false">
                          <template v-slot:selected-option="option">
                            <span class="courier" :class="option.code">
                              {{ option.label }}
                            </span>
                          </template>
                          <template slot="option" slot-scope="option">
                            <span class="courier" :class="option.code">
                              {{ option.label }}
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="flex-column">
                      <div class="label">
                        <span class="label-text">
                          {{ $t('Shipping method') }}
                        </span>
                        <v-select name="shipping_method" ref="shipping_method" @input="SetupShipmentTemplate" v-model="modal.create.shipment.shipping.method" :options="modal.create.shipment.setup.option.method[modal.create.shipment.shipping.agent.code]" :clearable="false">
                          <template v-slot:selected-option="option">
                            <span class="courier" :class="option.agent">
                              {{ option.label }}
                            </span>
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="truncate">
                              <span class="courier" :class="option.agent">
                                {{ option.label }}
                              </span>
                              <div v-if="option.subtitle" class="subtitle">{{ $t(option.subtitle) }}</div>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
                <form :class="{disabled: modal.create.shipment.form.disabled}" @submit.prevent>
                  <button id="tab-page-form-0" type="submit" hidden />
                  <div v-for="(group, index) in modal.create.shipment.tab.page[0] && modal.create.shipment.tab.page[0].group" :key="index" :class="['flex-group', {'border-top': index == 0}]">
                    <div class="flex-row">
                      <div v-for="(field, index) in (group || {}).field || []" :key="index" :class="['flex-column', {flex1: field.fill_width}]" v-show="!field.hidden">
                        <div :class="['label', {required: field.required}]">
                          <span class="label-text" v-show="field.type != 'list'">
                            {{ $t(field.label || Capitalize(field.name).replace(/_/g, ' ')) }}
                          </span>
                          <v-select v-if="field.type == 'select' && render" :class="{'vs--loading': field.loading}" :name="field.name" :ref="field.ref" :required="field.required" :disabled="field.disabled" :value="field.value" :multiple="field.multiple" :options="field.options" :title="field.title" :data-widget="field.widget" :data-role="field.role" :clearable="field.clearable" v-on="field.input ? {input: field.input} : {}">
                            <template slot="spinner">
                              <div class="vs__spinner" v-show="field.loading" />
                            </template>
                            <template #search="{attributes, events}">
                              <input class="vs__search" v-bind="attributes" v-on="events" :required="field.required && !(Array.isArray(modal.create.shipment.form.data[field.name]) ? modal.create.shipment.form.data[field.name].length : modal.create.shipment.form.data[field.name])">
                            </template>
                            <template v-slot:selected-option="option">
                              <div class="truncate" :title="field.value.title">
                                <Flag v-if="/currency|country/.test(field.ref)" :code="option.iso || option.code" size="small" />
                                <span>{{ option.label }}</span>
                              </div>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="truncate" :title="option.title">
                                <Flag v-if="/currency|country/.test(field.ref)" :code="option.iso || option.code" size="small" />
                                <span>{{ option.label }}</span>
                              </div>
                            </template>
                            <!-- <template slot="no-options">Sorry, no matching options.</template> -->
                          </v-select>
                          <div v-else-if="field.type == 'datetime-local' && browser.firefox" class="v-wrapper border" :title="field.title">
                            <input class="v-wrapper-input" type="date" :name="field.name" :ref="field.ref" :required="field.required" :readonly="field.readonly" :min="field.min.split('T')[0]" :value="modal.create.shipment.form.data[field.name] && modal.create.shipment.form.data[field.name].split('T')[0]" @keydown.enter="$event.target.blur()" @input="HandleCustomCreateShipmentDatetimeInputField" @blur="HandleCustomCreateShipmentDatetimeInputField">
                            <input class="v-wrapper-input" type="time" :name="field.name" :ref="field.ref" :required="field.required" :readonly="field.readonly" :value="modal.create.shipment.form.data[field.name] && modal.create.shipment.form.data[field.name].split('T').slice(-1)[0]" @keydown.enter="$event.target.blur()" @input="HandleCustomCreateShipmentDatetimeInputField" @blur="HandleCustomCreateShipmentDatetimeInputField">
                          </div>
                          <div v-else-if="field.widget == 'address_book'" class="v-wrapper border">
                            <input class="v-wrapper-input" style="height: 32px; border: 0;" :type="field.type" :name="field.name" :ref="field.ref" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" :min="field.min" :max="field.max" :minlength="field.minlength" :step="field.step" :value="field.value" :title="field.title" :data-widget="field.widget" v-model="modal.create.shipment.form.data[field.name]" @keydown.enter="$event.target.blur()" @input="field.input ? field.input($event) : {}" @blur="HandleCreateShipmentInputField" v-on="field.blur ? {blur: field.blur} : {}">
                            <a class="v-wrapper-input-button icon dots" :ref="field.role + '_' + field.widget" @mousedown.prevent="ToggleVisibility" @mouseup.prevent="ToggleVisibility" @click.prevent href="" :tabindex="-1">
                              <ul class="v-wrapper-input-button-actions">
                                <li style="padding: 8px 15px;">
                                  <div style="width: 100%; min-width: 293px;">
                                    <v-select :class="{'vs--loading': address.loading}" :value="address[field.role].selected" :options="field.options" @input="SelectAddress(field.role, $event)" :clearable="true">
                                      <template slot="spinner">
                                        <div class="vs__spinner" v-show="address.loading" />
                                      </template>
                                      <template v-slot:selected-option="option">
                                        <div class="truncate" :title="option.label">
                                          <span>{{ option.label }}</span>
                                        </div>
                                      </template>
                                      <template slot="option" slot-scope="option">
                                        <div class="truncate" :title="option.label">
                                          <span>{{ option.label }}</span>
                                        </div>
                                      </template>
                                    </v-select>
                                  </div>
                                  <!-- <a class="button" @click.prevent="RefreshAddressOptions" href="" style="margin-left: 10px;">Refresh</a> -->
                                </li>
                                <li v-if="field.role == 'sender' && address.sender.selected && JSON.stringify(address.sender.selected) != JSON.stringify(address.sender.default)">
                                  <a @click.prevent="SetDefaultSenderAddress" href="" class="green">{{ $t('Save as default sender address') }}</a>
                                </li>
                                <li v-if="field.role == 'sender' && address.sender.default">
                                  <a @click.prevent="RemoveDefaultSenderAddress" href="" class="red">{{ $t('Remove default sender address') }}</a>
                                </li>
                              </ul>
                            </a>
                          </div>
                          <div v-else-if="field.list && /customs_declaration/.test(field.widget)" class="v-list-wrapper">
                            <div class="v-list-head">
                              <p class="v-list-head-column quantity">{{ $t('Quantity') }}</p>
                              <p class="v-list-head-column country">{{ $t('Country') }}</p>
                              <p class="v-list-head-column content">{{ $t('Content (in English)') }}</p>
                              <p class="v-list-head-column tariff">{{ $t('Tariff') }}</p>
                              <p class="v-list-head-column weight">{{ $t('Unit weight') }}</p>
                              <p class="v-list-head-column value">{{ $t('Unit value') }}</p>
                              <p class="v-list-head-column total">{{ $t('Total value') }}</p>
                              <p style="width: 34px; flex-grow: 0; flex-shrink: 0;"></p>
                            </div>
                            <ul class="v-list-body">
                              <li class="v-list-row" style="border-bottom: 1px solid rgba(60, 60, 60, 0.26);">
                                <input class="v-list-row-column input quantity" type="number" min="0" step="1" ref="list-item_quantity" data-key="quantity" v-model="field.list.item.quantity" @input="ItemInput($event, field)" @blur="ItemBlur($event, field)" @keydown.enter="$event.target.blur()">
                                <input class="v-list-row-column input country" list="country-codes" spellcheck="false" ref="list-item_country" data-key="country" v-model="field.list.item.country" @change="ItemInput($event, field)" @blur="ItemBlur($event, field)" @keydown.enter="$event.target.blur()">
                                <datalist id="country-codes"><option v-for="(option, index) in country.codes/*.filter(code => !country.eu.some(eu => eu.code == code))*/" :key="index">{{ option }}</option></datalist>
                                <input class="v-list-row-column input content" type="text" spellcheck="false" ref="list-item_content" data-key="content" v-model="field.list.item.content" @input="ItemInput($event, field)" @blur="ItemBlur($event, field)" @keydown.enter="$event.target.blur()">
                                <input class="v-list-row-column input tariff" spellcheck="false" ref="list-item_tariff" data-key="tariff" v-model="field.list.item.tariff" @input="ItemInput($event, field)" @blur="ItemBlur($event, field)" @keydown.enter="$event.target.blur()" placeholder="[0-9]{6,8,10}" pattern="^([0-9]{2})?([0-9]{2})?([0-9]{6})$">
                                <input class="v-list-row-column input weight" type="number" min="0" step="0.01" ref="list-item_weight" data-key="weight" v-model="field.list.item.weight" @input="ItemInput($event, field)" @blur="ItemBlur($event, field)" @keydown.enter="$event.target.blur()" placeholder="Kg">
                                <input class="v-list-row-column input value" type="number" min="0" step="0.01" ref="list-item_value" data-key="value" v-model="field.list.item.value" @input="ItemInput($event, field)" @blur="ItemBlur($event, field)" @keydown.enter="$event.target.blur()" :placeholder="field.list.currency">
                                <p class="v-list-row-column total" ref="list-item_total">{{ CurrencyFormat(field.list.item.total || '0.00', 2) }} {{ field.list.currency }}</p>
                                <a class="v-list-row-column button add" @click.prevent="AddItem(field)" />
                              </li>
                              <li class="v-list-row" v-for="(item, index) in field.list.items" :key="index" :data-index="index" :data-name="field.name" @mouseenter="HoverItem" @mouseleave="HoverItem">
                                <input class="v-list-row-column input quantity" type="number" min="0" step="1" data-key="quantity" v-model="item.quantity" @input="ItemInput($event, item)" @blur="ItemBlur($event, item)" @keydown.enter="$event.target.blur()">
                                <input class="v-list-row-column input country" :list="'country-codes-' + index" spellcheck="false" data-key="country" v-model="item.country" @change="ItemInput($event, item)" @blur="ItemBlur($event, item)" @keydown.enter="$event.target.blur()">
                                <datalist :id="'country-codes-' + index"><option v-for="(option, index) in country.codes/*.filter(code => !country.eu.some(eu => eu.code == code))*/" :key="index">{{ option }}</option></datalist>
                                <input class="v-list-row-column input content" type="text" spellcheck="false" data-key="content" v-model="item.content" @input="ItemInput($event, item)" @blur="ItemBlur($event, item)" @keydown.enter="$event.target.blur()">
                                <input class="v-list-row-column input tariff" spellcheck="false" data-key="tariff" v-model="item.tariff" @input="ItemInput($event, item)" @blur="ItemBlur($event, item)" @keydown.enter="$event.target.blur()" placeholder="[0-9]{6,8,10}" pattern="^([0-9]{2})?([0-9]{2})?([0-9]{6})$">
                                <input class="v-list-row-column input weight" type="number" min="0" step="0.01" data-key="weight" v-model="item.weight" @input="ItemInput($event, item)" @blur="ItemBlur($event, item)" @keydown.enter="$event.target.blur()" placeholder="Kg">
                                <input class="v-list-row-column input value" type="number" min="0" step="0.01" data-key="value" v-model="item.value" @input="ItemInput($event, item)" @blur="ItemBlur($event, item)" @keydown.enter="$event.target.blur()" :placeholder="field.list.currency">
                                <p class="v-list-row-column total">{{ CurrencyFormat(item.total || '0.00', 2) }} {{ field.list.currency }}</p>
                                <a class="v-list-row-column button remove" @click.prevent="RemoveItem(index, field)" />
                              </li>
                              <li class="v-list-row">
                                <p class="v-list-row-column quantity" />
                                <p class="v-list-row-column country" />
                                <p class="v-list-row-column content" />
                                <p class="v-list-row-column tariff" />
                                <p class="v-list-row-column weight" />
                                <p class="v-list-row-column value" />
                                <p class="v-list-row-column total">{{ CurrencyFormat(((Number(field.list.item.total || 0)) + field.list.items.reduce((a, b) => Number(a || 0) + Number(b.total || 0), 0)) || '0.00', 2) }} {{ field.list.currency }}</p>
                                <p class="v-list-row-column button" style="cursor: default;" />
                              </li>
                            </ul>
                          </div>
                          <input v-else class="v-input" :type="field.type" :name="field.name" :ref="field.ref" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" :min="field.min" :max="field.max" :minlength="field.minlength" :step="field.step" :value="field.value" :title="field.title" :data-widget="field.widget" v-model="modal.create.shipment.form.data[field.name]" @keydown.enter="$event.target.blur()" @input="field.input ? field.input($event) : {}" @blur="HandleCreateShipmentInputField" v-on="field.blur ? {blur: field.blur} : {}">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </li>
              <li v-for="(tab, index) in modal.create.shipment.tab.page.slice(1, modal.create.shipment.tab.page.length)" :key="index + 1" class="modal-tabs__body-content padding" v-show="modal.create.shipment.tab.active == index + 1">
                <form @submit.prevent>
                  <button :id="'tab-page-form-' + (index + 1)" type="submit" hidden />
                  <div v-for="(group, index) in tab.group" :key="index" class="flex-group">
                    <div class="flex-row">
                      <div v-for="(field, index) in (group || {}).field || []" :key="index" :class="['flex-column', {flex1: field.fill_width, half: field.half_width}]" v-show="!field.hidden">
                        <div :class="['label', {required: field.required}]">
                          <span class="label-text">
                            {{ $t(field.label || Capitalize(field.name).replace(/_/g, ' ')) }}
                          </span>
                          <v-select v-if="field.type == 'select' && render" :class="{'vs--loading': field.loading}" :name="field.name" :ref="field.ref" :required="field.required" :disabled="field.disabled" :value="field.value" :multiple="field.multiple" :options="field.options" :title="field.title" :data-widget="field.widget" :data-role="field.role" :clearable="field.clearable" v-on="field.input ? {input: field.input} : {}">
                            <template slot="spinner">
                              <div class="vs__spinner" v-show="field.loading" />
                            </template>
                            <template #search="{attributes, events}">
                              <input class="vs__search" v-bind="attributes" v-on="events" :required="field.required && !(Array.isArray(modal.create.shipment.form.data[field.name]) ? modal.create.shipment.form.data[field.name].length : modal.create.shipment.form.data[field.name])">
                            </template>
                            <template v-slot:selected-option="option">
                              <div class="truncate" :title="field.value.title">
                                <Flag v-if="field.ref.includes('country')" :code="option.code" size="small" />
                                <span>{{ option.label }}</span>
                              </div>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="truncate" :title="option.title">
                                <Flag v-if="field.ref.includes('country')" :code="option.code" size="small" />
                                <span>{{ option.label }}</span>
                              </div>
                            </template>
                            <!-- <template slot="no-options">Sorry, no matching options.</template> -->
                          </v-select>
                          <div v-else-if="field.type == 'datetime-local' && browser.firefox" class="v-wrapper border" :title="field.title" :data-widget="field.widget">
                            <input class="v-wrapper-input" type="date" :name="field.name" :ref="field.ref" :required="field.required" :readonly="field.readonly" :value="modal.create.shipment.form.data[field.name] && modal.create.shipment.form.data[field.name].split('T')[0]" @keydown.enter="$event.target.blur()" @input="HandleCustomCreateShipmentDatetimeInputField" @blur="HandleCustomCreateShipmentDatetimeInputField">
                            <input class="v-wrapper-input" type="time" :name="field.name" :ref="field.ref" :required="field.required" :readonly="field.readonly" :value="modal.create.shipment.form.data[field.name] && modal.create.shipment.form.data[field.name].split('T').slice(-1)[0]" @keydown.enter="$event.target.blur()" @input="HandleCustomCreateShipmentDatetimeInputField" @blur="HandleCustomCreateShipmentDatetimeInputField">
                          </div>
                          <div v-else-if="field.widget == 'address_book'" class="v-wrapper border">
                            <input class="v-wrapper-input" style="height: 32px; border: 0;" :type="field.type" :name="field.name" :ref="field.ref" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" :min="field.min" :max="field.max" :minlength="field.minlength" :step="field.step" :value="field.value" :title="field.title" :data-widget="field.widget" v-model="modal.create.shipment.form.data[field.name]" @keydown.enter="$event.target.blur()" @input="field.input ? field.input($event) : {}" @blur="HandleCreateShipmentInputField" v-on="field.blur ? {blur: field.blur} : {}">
                            <a class="v-wrapper-input-button icon dots" :ref="field.role + '_' + field.widget" @mousedown.prevent="ToggleVisibility" @mouseup.prevent="ToggleVisibility" @click.prevent href="" :tabindex="-1">
                              <ul class="v-wrapper-input-button-actions">
                                <li style="padding: 8px 15px;">
                                  <div style="width: 100%; min-width: 293px;">
                                    <v-select :class="{'vs--loading': address.loading}" :value="address[field.role].selected" :options="field.options" @input="SelectAddress(field.role, $event)" :clearable="true">
                                      <template slot="spinner">
                                        <div class="vs__spinner" v-show="address.loading" />
                                      </template>
                                      <template v-slot:selected-option="option">
                                        <div class="truncate" :title="option.label">
                                          <span>{{ option.label }}</span>
                                        </div>
                                      </template>
                                      <template slot="option" slot-scope="option">
                                        <div class="truncate" :title="option.label">
                                          <span>{{ option.label }}</span>
                                        </div>
                                      </template>
                                    </v-select>
                                  </div>
                                  <!-- <a class="button" @click.prevent="RefreshAddressOptions" href="" style="margin-left: 10px;">Refresh</a> -->
                                </li>
                                <li v-if="field.role == 'sender' && address.sender.selected && !/return/.test(modal.create.shipment.shipping.method.code) && JSON.stringify(address.sender.selected) != JSON.stringify(address.sender.default)">
                                  <a @click.prevent="SetDefaultSenderAddress" href="" class="green">{{ $t('Save as default sender address') }}</a>
                                </li>
                                <li v-if="field.role == 'sender' && address.sender.default && !/return/.test(modal.create.shipment.shipping.method.code)">
                                  <a @click.prevent="RemoveDefaultSenderAddress" href="" class="red">{{ $t('Remove default sender address') }}</a>
                                </li>
                              </ul>
                            </a>
                          </div>
                          <div v-else-if="field.widget == 'phone_prefix'" class="v-wrapper border" :title="field.title" :data-widget="field.widget" :data-ref="field.ref">
                            <div class="v-wrapper-select">
                              <v-select :name="field.name" :readonly="field.readonly" :value="field.selected" :filter="fuseSearch" :options="field.options.map(o => {o.name = CountryName(o.iso); return o;}).sort((a, b) => {
                                a = a.name.toUpperCase(); b = b.name.toUpperCase(); return a < b ? -1 : a > b ? 1 : 0;
                              })" @input="SelectPhonePrefix(field, $event)" :clearable="false">
                                <template v-slot:selected-option="option">
                                  <div class="truncate">
                                    <Flag :code="option.iso" size="small" />
                                    <span>{{ option.code }}</span>
                                  </div>
                                </template>
                                <template slot="option" slot-scope="option">
                                  <div class="truncate">
                                    <Flag :code="option.iso" size="small" />
                                    <span>{{ option.code }}</span>
                                  </div>
                                </template>
                              </v-select>
                            </div>
                            <input class="v-wrapper-input" type="tel" style="border: 0;" :name="field.name" :ref="field.ref" :required="field.required" :readonly="field.readonly" :value="modal.create.shipment.form.data[field.name] && modal.create.shipment.form.data[field.name].replace(field.selected ? new RegExp(field.selected.code.replace('+', '\\+')) : '', '')" @keydown.enter="$event.target.blur()" @blur="HandleCustomCreateShipmentPhonePrefixInputField">
                          </div>
                          <!--
                          <div v-else-if="field.widget == 'phone_type'" class="v-wrapper border" :title="field.title" :data-widget="field.widget" :data-ref="field.ref">
                            <div class="v-wrapper-select">
                              
                            </div>
                          </div>
                          -->
                          <input v-else class="v-input" :type="field.type" :name="field.name" :ref="field.ref" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" :min="field.min" :max="field.max" :minlength="field.minlength" :step="field.step" :value="field.value" :title="field.title" :data-widget="field.widget" v-model="modal.create.shipment.form.data[field.name]" @keydown.enter="$event.target.blur()" @input="field.input ? field.input($event) : {}" @blur="HandleCreateShipmentInputField" v-on="field.blur ? {blur: field.blur} : {}">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer footer-content">
          <div class="button-container" style="justify-content: space-evenly;">
            <button class="button icon chevron-left" :disabled="!modal.create.shipment.form.step.back" @click.prevent="CreateShipmentStep('back')">{{ $t('Back') }}</button>
            <button class="button icon-after chevron-right" :disabled="!modal.create.shipment.template || modal.create.shipment.form.disabled" v-if="modal.create.shipment.form.step.next" @click.prevent="ValidateCreateShipmentStep(() => CreateShipmentStep('next'))">{{ $t('Next') }}</button>
            <!-- <button class="button green" :disabled="!modal.create.shipment.template" v-if="modal.create.shipment.form.step.last" @click.prevent="ValidateCreateShipmentStep(() => SubmitCreateShipmentForm())">{{ $t('Create new') }}</button> -->
            <a :class="['button action-toggle', {disabled: !modal.create.shipment.template}]" ref="create_shipment_submit_button" v-if="modal.create.shipment.form.step.last" @click.prevent="ValidateCreateShipmentStep(() => modal.create.shipment.template && $event.target.classList.toggle('visible'))" href="">
              {{ $t('Create') }}
              <ul class="button-actions">
                <li>
                  <a @click.prevent="SubmitCreateShipmentForm('email'), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Email') }}</a>
                </li>
                <li>
                  <a @click.prevent="SubmitCreateShipmentForm('print'), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Print') }}</a>
                </li>
                <li>
                  <a @click.prevent="SubmitCreateShipmentForm('download'), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Download') }}</a>
                </li>
              </ul>
              <span class="button-icon caret-up" />
            </a>
          </div>
        </div>
      </div>
    </Modal>

    <Modal modal="view.shipment" :value="modal.view.shipment.open" :title="$t('Shipment') + ' ' + modal.view.shipment.title">
      <div id="view-shipment" v-if="modal.view.shipment.open">
        <div class="modal-header">
          <!--
          <div class="shipping trackingnumber" style="display: flex; align-items: center;">
            <span :class="['courier-icon', shipment.agent.code]" :title="shipment.agent.label" />
            <span class="shipping-method">{{ shipment.method.label }}</span>
          </div>
          -->
        </div>
        <div class="modal-content scrollable" style="padding-bottom: 25px;">
          <div :class="['information', {reverse: shipment.type_code.includes('return')}]">
            <span class="info-primary" style="justify-content: flex-start;">
              <h3 class="info-heading">
                {{ $t(shipment.type_code.includes('return') ? 'Recipient' : 'Sender') }}
              </h3>
              <p v-if="shipment.sender.name" class="info-row name" v-html="Hyperlink({scheme: 'google', target: '_blank', href: shipment.sender.name})" />
              <p v-if="shipment.sender.address.street" class="info-row address" v-html="Hyperlink({scheme: 'map', target: '_blank', href: shipment.sender.address.string, text: shipment.sender.address.street})" />
              <p v-if="shipment.sender.address.postal && shipment.sender.address.city" class="info-row postal-city" v-html="Hyperlink({scheme: 'map', target: '_blank', href: shipment.sender.address.postal + ' ' + shipment.sender.address.city})" />
              <div class="info-row postal-country" style="align-items: center; justify-content: flex-start;">
                <Flag :code="shipment.sender.country.code" size="small" />
                <div v-html="Hyperlink({scheme: 'map', target: '_blank', href: CountryName(shipment.sender.country.code), style: 'margin-left: 10px;'})" />
              </div>
              <p v-if="shipment.sender.phone" class="info-row phone" v-html="Hyperlink({scheme: 'tel', href: shipment.sender.phone, country: shipment.sender.country.code, text: shipment.sender.phone})" />
              <p v-if="shipment.sender.email" class="info-row email" v-html="Hyperlink({scheme: 'mailto', href: shipment.sender.email})" />
            </span>
            <span class="spacer"><b class="arrow-thick"></b></span>
            <span class="info-primary" style="justify-content: flex-start;">
              <h3 class="info-heading">
                {{ $t(shipment.type_code.includes('return') ? 'Sender' : 'Recipient') }}
              </h3>
              <p v-if="shipment.recipient.name" class="info-row name" v-html="Hyperlink({scheme: 'google', target: '_blank', href: shipment.recipient.name})" />
              <p v-if="shipment.recipient.address.street" class="info-row address" v-html="Hyperlink({scheme: 'map', target: '_blank', href: shipment.recipient.address.string, text: shipment.recipient.address.street})" />
              <p v-if="shipment.recipient.address.postal && shipment.recipient.address.city" class="info-row postal-city" v-html="Hyperlink({scheme: 'map', target: '_blank', href: shipment.recipient.address.postal + ' ' + shipment.recipient.address.city})" />
              <div class="info-row postal-country" style="align-items: center; justify-content: flex-start;">
                <Flag :code="shipment.recipient.country.code" size="small" />
                <div v-html="Hyperlink({scheme: 'map', target: '_blank', href: CountryName(shipment.recipient.country.code), style: 'margin-left: 10px;'})" />
              </div>
              <p v-if="shipment.recipient.phone" class="info-row phone" v-html="Hyperlink({scheme: 'tel', href: shipment.recipient.phone, country: shipment.recipient.country.code, text: shipment.recipient.phone})" />
              <p v-if="shipment.recipient.email" class="info-row email" v-html="Hyperlink({scheme: 'mailto', href: shipment.recipient.email})" />
            </span>
          </div>
          <div class="information">
            <span class="info-primary" style="justify-content: flex-start; border: 2px dashed #eaeaea; padding: 30px;">
              <h3 class="info-heading">
                {{ $t('Shipment') }}
              </h3>
              <div class="info-row" style="align-items: center;">
                <!--
                <span class="shipping trackingnumber" style="display: flex; align-items: center;">
                  <span :class="['courier-icon', shipment.agent.code]" :title="shipment.agent.label" />
                  <span class="shipping-method" style="max-width: 100%; text-align: left;">{{ shipment.method.label }}</span>
                </span>
                <span>{{ shipment.tracking }}</span>
                -->
                <div class="shipping trackingnumber" v-html="Hyperlink({target: '_blank', href: TrackingURL({agent: shipment.agent.code, code: shipment.tracking, tracking: false, company: shipment.company.id}), html: `<span class='courier-icon ${shipment.agent.code}' title='${shipment.agent.label}'></span><span class='shipping-method' style='max-width: 100%; text-align: left;'>${shipment.method.label}</span>`, style: 'width: 100%; display: flex; align-items: center;'})" />
                <div class="shipping tnt" v-html="Hyperlink({target: '_blank', href: TrackingURL({agent: shipment.agent.code, code: shipment.tracking, tracking: true, company: shipment.company.id}), text: shipment.tracking})" />
              </div>
              <p class="info-row">
                {{ $t('Type') }}
                <span>{{ shipment.type.map(word => /\{\{.*?\}\}/g.test(word) ? $t(word.replace(/[{}]/g, '')) : word).join('') }}</span>
              </p>
              <p class="info-row">
                {{ $t('Action') }}
                <span>{{ shipment.action }}</span>
              </p>
              <p v-if="shipment.reference" class="info-row">
                {{ $t('Reference') }}
                <span>{{ shipment.reference }}</span>
              </p>
            </span>
            <span class="info-secondary" style="justify-content: flex-start;" :style="!shipment.shop.address.street ? 'opacity: 0; visibility: hidden;' : ''">
              <h3 class="info-heading">
                {{ $t('Pick-up point') }}
              </h3>
              <p v-if="shipment.shop.name" class="info-row name" v-html="Hyperlink({scheme: 'google', target: '_blank', href: shipment.shop.name})" />
              <p v-if="shipment.shop.address.street" class="info-row address" v-html="Hyperlink({scheme: 'map', target: '_blank', href: String(shipment.shop.address.string).replace(/\n?\s?pakkeshop:\s?.*,/i, ','), text: shipment.shop.address.street})" />
              <p v-if="shipment.shop.address.postal && shipment.shop.address.city" class="info-row postal-city" v-html="Hyperlink({scheme: 'map', target: '_blank', href: shipment.shop.address.postal + ' ' + shipment.shop.address.city})" />
              <div class="info-row postal-country" style="align-items: center; justify-content: flex-start;">
                <Flag :code="shipment[shipment.type_code.includes('return') ? 'sender' : 'recipient'].country.code" size="small" />
                <div v-html="Hyperlink({scheme: 'map', target: '_blank', href: CountryName(shipment[shipment.type_code.includes('return') ? 'sender' : 'recipient'].country.code), style: 'margin-left: 10px;'})" />
              </div>
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <button v-if="shipment.agent.code == 'dao'" class="button red" style="margin-right: auto;" @click.prevent="CancelShipment(shipment)">{{ $t('Cancel') }}</button>
            <button class="button green" @click.prevent="OpenPDF(shipment.id)">{{ $t('Open PDF') }}</button>
            <button class="button green" @click.prevent="DownloadPDF(shipment)">{{ $t('Download') }}</button>
            <button class="button blue" @click.prevent="PrintPDF(shipment)">{{ $t('Print PDF') }}</button>
            <a v-if="shipment.returnable" class="button action-toggle right" @click.prevent="$event.target.classList.toggle('visible')" href="">
              {{ $t('Create new') }}
              <ul class="button-actions">
                <li>
                  <a @click.prevent="OpenCreateShipmentFormModal(shipment.original), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Shipping label') }}</a>
                </li>
                <li>
                  <a @click.prevent="OpenCreateShipmentFormModal(shipment.original, true), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Return label') }}</a>
                </li>
              </ul>
              <span class="button-icon caret-up" />
            </a>
            <button v-else class="button blue" @click.prevent="OpenCreateShipmentFormModal(shipment.original)">{{ $t('Create new') }}</button>
          </div>
        </div>
      </div>
    </Modal>

    <transition name="fade">
      <div @mousedown="PopupMouseEvent" @mouseup="PopupMouseEvent" class="validate-popup" v-if="popup.show">
        <div class="validate-popup__content">
          <form id="popup-form" @submit.prevent>
            <button type="submit" hidden />
            <div class="titles">
              <p class="title">Are you sure?</p>
              <p class="subtitle" v-html="popup.message" />
            </div>
            <div style="width: 750px; margin-bottom: 50px;">
              <div class="flex-group flex-column">
                <div class="flex-group" v-if="modal.create.shipment.action != 'print'">
                  <div class="flex-row">
                    <div class="flex-column" style="flex: 1;">
                      <div :class="['label', {required: modal.create.shipment.action != 'print'}]">
                        <span class="label-text">Title </span>
                        <input class="v-input" type="text" name="title" v-model="modal.create.shipment.title" @keydown.enter="$event.target.blur()">
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="modal.create.shipment.action == 'email'" class="flex-group">
                  <div class="flex-row">
                    <div class="flex-column">
                      <div class="label required">
                        <span class="label-text">Language </span>
                        <v-select name="language" v-model="modal.create.shipment.language.selected" :options="modal.create.shipment.language.options" :clearable="false">
                          <template #search="{attributes, events}">
                            <input class="vs__search" v-bind="attributes" v-on="events" :required="!modal.create.shipment.language.selected.code">
                          </template>
                          <template v-slot:selected-option="option">
                            <div class="truncate">
                              <Flag :code="option.code" size="small" type="language" />
                              <span>{{ option.label }}</span>
                            </div>
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="truncate">
                              <Flag :code="option.code" size="small" type="language" />
                              <span>{{ option.label }}</span>
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="flex-column">
                      <div class="label required">
                        <span class="label-text">To </span>
                        <input class="v-input" type="text" name="email_to" v-model="modal.create.shipment.email.to" @keydown.enter="$event.target.blur()" required>
                      </div>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="flex-column">
                      <div class="label required">
                        <span class="label-text">Name </span>
                        <input class="v-input" type="text" name="email_name" v-model="modal.create.shipment.email.name" @keydown.enter="$event.target.blur()" required>
                      </div>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="flex-column">
                      <div class="label required">
                        <span class="label-text">Subject </span>
                        <input class="v-input" type="text" name="email_subject" v-model="modal.create.shipment.email.subject" @keydown.enter="$event.target.blur()" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="modal.create.shipment.action == 'print'" class="flex-group">
                  <div class="flex-row">
                    <div class="flex-column">
                      <div class="label required">
                        <span class="label-text">{{ $t('Label printer') }} </span>
                        <div class="v-select-wrapper">
                          <v-select :class="{'vs--loading': printer.loading}" v-model="printer.selected" :options="printer.options" :clearable="false">
                            <template slot="spinner">
                              <div class="vs__spinner" v-show="printer.loading" />
                            </template>
                            <template #search="{attributes, events}">
                              <input class="vs__search" v-bind="attributes" v-on="events" :required="!printer.selected.id">
                            </template>
                            <template v-slot:selected-option="option">
                              <div class="truncate" :title="printer.selected.title">
                                <span :class="['state', option.color]">{{ option.label }}</span>
                              </div>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="truncate" :title="option.title">
                                <span :class="['state', option.color]">{{ option.label }}</span>
                              </div>
                            </template>
                          </v-select>
                          <!-- <a class="button" @click.prevent="RefreshPrinterOptions" href="">Refresh</a> -->
                        </diV>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="actions">
              <a class="btn-stateless cancel" @click.prevent="$event.preventDefault(), popup.button.cancel.click()" href="">{{ popup.button.cancel.text }}</a>
              <a class="btn-success confirm" @click.prevent="popup.button.confirm.click" href="">{{ popup.button.confirm.text + popup.action + ' it' }}</a>
            </div>
          </form>
        </div>
      </div>
    </transition>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { Permissions }            from '@/helpers/Permissions';
  import { Config }                 from '@/helpers/Config';
  import { Tool }                   from '@/helpers/Tool';
  import { BPA }                    from '@/helpers/BPA';
  import Punycode                   from '@/helpers/Punycode';
  import Modal                      from '@/components/snippets/Modal';
  import Flag                       from '@/components/snippets/Flag';
  import Fuse                       from 'fuse.js';

  export default {
    name: 'ParcelsShipmentsList',
    mixins: [
      TableElementsBehaviour, 
      FormElementsBehaviour,
      Permissions,
      Config,
      Tool,
      BPA
    ],
    components: {
      Modal,
      Flag
    },
    data() {
      return {
        loading: true,
        render: true,
        entries: 0,
        couriers: {},
        companies: [],
        clipboard: null,
        cached: {},
        clickables: [],
        shipments: [],
        shipment: {},
        postal: {},
        quick: {
          loading: false,
          nodrop: false,
          selected: '',
          company: {},
          options: []
        },
        company: {
          selected: null,
          options: []
        },
        courier: {
          selected: null,
          options: []
        },
        country: {
          selected: null,
          options: [],
          codes: ['AD','AE','AF','AG','AI','AL','AM','AO','AR','AS','AT','AU','AW','AZ','BA','BB','BD','BE','BF','BG','BH','BI','BJ','BM','BN','BO','BR','BS','BT','BW','BY','BZ','CA','CD','CF','CG','CH','CI','CK','CL','CM','CN','CO','CR','CU','CV','CY','CZ','DE','DJ','DK','DM','DO','DZ','EC','EE','EG','ER','ES','ET','FI','FJ','FK','FM','FO','FR','GA','GB','GD','GE','GF','GG','GH','GI','GL','GM','GN','GP','GQ','GR','GT','GU','GW','GY','HK','HN','HR','HT','HU','IC','ID','IE','IL','IN','IQ','IR','IS','IT','JE','JM','JO','JP','KE','KG','KH','KI','KM','KN','KP','KR','KW','KY','KZ','LA','LB','LC','LI','LK','LR','LS','LT','LU','LV','LY','MA','MC','MD','ME','MG','MH','MK','ML','MM','MN','MO','MP','MQ','MR','MS','MT','MU','MV','MW','MX','MY','MZ','NA','NC','NE','NG','NI','NL','NO','NP','NR','NU','NZ','OM','PA','PE','PF','PG','PH','PK','PL','PR','PT','PW','PY','QA','RE','RO','RS','RU','RW','SA','SB','SC','SD','SE','SG','SH','SI','SK','SL','SM','SN','SO','SR','SS','ST','SV','SY','SZ','TC','TD','TG','TH','TJ','TL','TN','TO','TR','TT','TV','TW','TZ','UA','UG','US','UY','UZ','VC','VE','VG','VI','VN','VU','WS','YE','YT','ZA','ZM','ZW'],
          eu: [
            {id: 40, code: 'AT', label: 'Austria'},
            {id: 56, code: 'BE', label: 'Belgium'},
            {id: 100, code: 'BG', label: 'Bulgaria'},
            {id: 191, code: 'HR', label: 'Croatia'},
            {id: 196, code: 'CY', label: 'Cyprus'},
            {id: 203, code: 'CZ', label: 'Czech Republic'},
            {id: 208, code: 'DK', label: 'Denmark'},
            {id: 233, code: 'EE', label: 'Estonia'},
            {id: 246, code: 'FI', label: 'Finland'},
            {id: 250, code: 'FR', label: 'France'},
            {id: 276, code: 'DE', label: 'Germany'},
            {id: 300, code: 'GR', label: 'Greece'},
            {id: 348, code: 'HU', label: 'Hungary'},
            {id: 372, code: 'IE', label: 'Ireland'},
            {id: 380, code: 'IT', label: 'Italy'},
            {id: 428, code: 'LV', label: 'Latvia'},
            {id: 440, code: 'LT', label: 'Lithuania'},
            {id: 442, code: 'LU', label: 'Luxembourg'},
            {id: 470, code: 'MT', label: 'Malta'},
            {id: 528, code: 'NL', label: 'Netherlands'},
            {id: 616, code: 'PL', label: 'Poland'},
            {id: 620, code: 'PT', label: 'Portugal'},
            {id: 642, code: 'RO', label: 'Romania'},
            {id: 703, code: 'SK', label: 'Slovakia'},
            {id: 705, code: 'SI', label: 'Slovenia'},
            {id: 724, code: 'ES', label: 'Spain'},
            {id: 752, code: 'SE', label: 'Sweden'}
          ],
          phone: {
            options: []
          }
        },
        printer: {
          loading: false,
          selected: null,
          options: []
        },
        shipping: {
          agent: null,
          method: null,
          option: {
            agent: [],
            method: {},
            methods: []
          }
        },
        date: {
          start: '',
          end: ''
        },
        search: {
          input: null,
          query: '',
          initial: null,
          company: '',
          country: '',
          agent: '',
          method: '',
          date: {
            start: '',
            end: ''
          },
          selects: []
        },
        page: {
          current: 1,
          number: 1,
          last: 1,
          order: {
            direction: 'desc',
            by: 'id' 
          }
        },
        address: {
          loading: false,
          initialized: false,
          sender: {
            selected: '',
            default: ''
          },
          recipient: {
            selected: ''
          },
          options: []
        },
        modal: {
          create: {
            mode: 'manual',
            shipment: {
              open: false,
              template: null,
              action: 'print',
              title: '',
              setup: {
                title: '',
                option: {
                  agent: [],
                  method: {},
                  shipping: {
                    agent: [],
                    method: {}
                  }
                },
                settings: null
              },
              shipping: {
                agent: '',
                method: ''
              },
              country: {
                sender: '',
                recipient: '',
                select: {
                  sender: {
                    loading: false
                  },
                  recipient: {
                    loading: false
                  }
                },
                options: {
                  sender: [],
                  recipient: []
                }
              },
              shop: {
                selected: '',
                options: []
              },
              availability: {
                selected: '',
                options: [],
                service: '',
                token: ''
              },
              language: {
                selected: {code: 'da_DK'},
                options: []
              },
              email: {
                to: '',
                name: '',
                subject: '',
                template_args: {}
              },
              subtitle: {
                bring: {
                  shop: 'To delivery location (pick-up)',
                  shop_bulk: 'To delivery location (pick-up)',
                  shop_return: 'From delivery location (return)',
                  shop_return_bulk: 'From delivery location (return)'
                },
                burd: {
                  private: 'To private address (quick delivery)',
                },
                dao: {
                  direkte: 'To private address (delivery)',
                  direkte_udland: 'To private address (delivery)',
                  parcel_shops: 'To delivery location (pick-up)',
                  return: 'From delivery location (return)'
                },
                dhl: {
                  parcel: 'To private address (delivery)',
                  return: 'From delivery location (return)'
                },
                gls: {
                  business: 'To business address (delivery)',
                  shop: 'To delivery location (pick-up)',
                  private: 'To private address (delivery)',
                  shop_return: 'From delivery location (return)'
                },
                instabox: {
                  collect_in_store: 'To delivery location (pick-up)',
                  express: 'To delivery location (pick-up)',
                  instahome: 'To private address (delivery)',
                  return_standalone: 'From delivery location (return)'
                },
                pdk: {
                  //business: 'To business address (delivery)',
                  shop: 'To delivery location (pick-up)',
                  private: 'To private address (delivery)',
                  private_with_customs_declaration: 'To private address (delivery)',
                  shop_return: 'From delivery location (return)'
                }
              },
              weight: {
                g: [
                  {code: 249, label: '0-0.25 kg'},
                  {code: 499, label: '0.25-0.50 kg'},
                  {code: 999, label: '0.50-1 kg'},
                  {code: 1999, label: '1-2 kg'},
                  {code: 2999, label: '2-3 kg'}
                ],
                kg: [
                  {code: 0.9999, label: '0-1 kg'},
                  {code: 4.9999, label: '1-5 kg'},
                  {code: 9.9999, label: '5-10 kg'},
                  {code: 14.9999, label: '10-15 kg'},
                  {code: 19.9999, label: '15-20 kg'}
                ]
              },
              service: {
                pdk: {
                  options: [],
                  countries: {
                    P19DK: ['AX', 'DK', 'FI', 'NO', 'SE'],
                    P19DKBP: ['NO'],
                    P19DKDPD: ['AT', 'BE', 'BG', 'CH', 'CZ', 'DE', 'EE', 'ES', 'FR', 'GB', 'HR', 'IE', 'IT', 'LI', 'LT', 'LU', 'LV', 'MC', 'NL', 'PL', 'PT', 'RO', 'SI', 'SK'],
                    PDK17: ['AX', 'DK', 'FI', 'FO', 'GL', 'IS', 'NO', 'SE'],
                    PDK17BP: ['NO'],
                    PDK17WORLD: ['AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AN', 'AO', 'AR', 'AU', 'AW', 'AZ', 'BA', 'BB', 'BD', 'BF', 'BH', 'BI', 'BJ', 'BM', 'BN', 'BO', 'BR', 'BS', 'BT', 'BW', 'BY', 'BZ', 'CA', 'CD', 'CF', 'CG', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CY', 'DJ', 'DM', 'DO', 'DZ', 'EC', 'EG', 'ER', 'ET', 'FJ', 'FK', 'FM', 'GA', 'GD', 'GE', 'GF', 'GH', 'GI', 'GM', 'GN', 'GP', 'GQ', 'GT', 'GU', 'GW', 'GY', 'HK', 'HN', 'HT', 'ID', 'IL', 'IN', 'IQ', 'IR', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LK', 'LR', 'LS', 'LY', 'MA', 'MD', 'ME', 'MG', 'MK', 'ML', 'MM', 'MN', 'MO', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NG', 'NI', 'NP', 'NR', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PM', 'PN', 'PR', 'PY', 'QA', 'RE', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SG', 'SH', 'SL', 'SM', 'SN', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TG', 'TH', 'TJ', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WS', 'XK', 'YT', 'ZA', 'ZM', 'ZW'],
                    PDK17DPD: ['AT', 'BE', 'BG', 'CH', 'CZ', 'DE', 'EE', 'ES', 'FI', 'FR', 'GB', 'GG', 'GR', 'HR', 'HU', 'IE', 'IM', 'IT', 'JE', 'LI', 'LT', 'LU', 'LV', 'MC', 'NL', 'PL', 'PT', 'RO', 'SI', 'SK']
                  }
                }
              },
              tab: {
                active: 0,
                page: []
              },
              form: {
                disabled: false,
                step: {
                  back: false,
                  next: false,
                  last: true
                },
                data: {}
              }
            }
          },
          view: {
            shipment: {
              open: false,
              title: '',
              tab: {
                active: 0,
                page: []
              }
            }
          }
        },
        popup: {
          show: false,
          mousedown: null,
          message: '',
          action: '',
          button: {
            cancel: {
              click() {},
              text: 'No'
            },
            confirm: {
              click() {},
              text: 'Yes, '
            }
          }
        },
        browser: {
          firefox: /firefox/i.test(navigator.userAgent)
        }
      }
    },
    created() {},
    async mounted() {
      this.clipboard = await Tool.PasteFromClipboard(true);

      // Modal
      this.$eventHub.$on('CloseModal', (modal_name) => {
        this.main.view.classList.remove('no-scroll');
        this.Modal(modal_name).open = false;
        if (modal_name == 'view.shipment') {
          this.shipment = {};
          this.cached.shipment = {};
          BPA.cache.session({name: this.$options.name, set: {shipment: {}}});
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].classList.contains('selected')) {
              this.clickables[i].classList.remove('selected');
            }
          }
        }
      });

      // Companies
      BPA.api.Companies('GET').map(company => {
        this.companies.push(company);
        this.company.options.push({
          id: company.id,
          code: company.code, 
          label: company.name,
          main: company.main
        });
      });
      
      // Countries
      BPA.api.Countries('GET').map(country => {
        const country_code = country.iso_code_2;
        if (this.country.codes.includes(country_code)) {
          this.country.options.push({
            id: country.id,
            code: country_code,
            label: country.name
          });
        }
      });
      
      // Phone codes
      this.country.phone.options = await this.GetPhoneCountryCodes();

      // Couries
      await this.UpdateShippingAgents();

      // Printers
      let printer_selected = BPA.printer.fetch('label');
      this.printer.options = await this.GetPrinterOptions();
      this.printer.selected = printer_selected ? Tool.PrinterOptions([printer_selected])[0] : '';

      // Language
      this.modal.create.shipment.language.options = BPA.locale('options').map(option => {
        option.code = option.code.replace('-', '_');
        option.label = this.$t(option.label.split(' ')[0]);
        if (option.code == this.locale) {
          this.modal.create.shipment.language.selected = option;
        }
        return option;
      });

      // Shipping
      const settings = await this.GetLabelGeneratorSetup(true);
      for (let agent in settings) {
        let option = this.courier.options.find(option => option.code == agent);
        if (option) this.shipping.option.agent.push({
          code: agent,
          label: option.label
        });
        this.shipping.option.method[agent] = [];
        for (let method in settings[agent]) {
          this.shipping.option.method[agent].push({
            code: method,
            agent: agent,
            label: settings[agent][method].name
          });
        }
        this.Alphabetize(this.shipping.option.method[agent], 'label');
      }
      this.Alphabetize(this.shipping.option.agent, 'label');
      this.shipping.option.methods = Object.values(this.shipping.option.method).flat();
      this.Alphabetize(this.shipping.option.methods, 'label');

      // Get Address Book addresses
      this.address.options = await this.GetAddressOptions();
      
      // Get default sender address
      this.address.sender.default = this.GetDefaultSenderAddress();

      // Create list of company shops for quick return label printing
      this.quick.company = this.address.options.find(option => option.id == 1) || {};
      this.quick.options = this.address.options.filter(option => option.id != 1 && new RegExp(`^${this.main.company}`, 'i').test(option.name) && option.printnode_id);
      this.Alphabetize(this.quick.options, 'label');

      // Add shop label printer connection status to shop options
      await this.SetQuickShopPrinterStatus(this.printer.options);

      // Load shipping agent methods based on sender and recipient country
      this.country.shipping = await this.GetCountryShippingAgentMethods();

      const sender_recipient = Object.keys(this.country.shipping);
      const country_options = [];
      const sender_country_options = [];
      const recipient_country_options = [];
      for (let i = 0; i < this.country.options.length; i++) {
        const country = this.country.options[i];
        const shipping_keys = sender_recipient.filter(key => key.split('_').includes(country.code));
        let sender_country_option = null;
        let recipient_country_option = null;
        for (let j = 0; j < shipping_keys.length; j++) {
          const key = shipping_keys[j];
          const sender_country_code = key.split('_')[0];
          const recipient_country_code = key.split('_')[1];
          const country_shipping = this.country.shipping[key];
          if (country_shipping && Object.keys(country_shipping).length) {
            if (!country_options.some(option => option.code == country.code)) {
              country_options.push(country);
            }
            if (sender_country_code == country.code) {
              sender_country_option = true;
            }
            if (recipient_country_code == country.code) {
              recipient_country_option = true;
            }
          }
        }
        if (sender_country_option) {
          sender_country_options.push(country);
        }
        if (recipient_country_option) {
          recipient_country_options.push(country);
        }
      }
      this.country.options = country_options;
      this.modal.create.shipment.country.options.sender = sender_country_options;
      this.modal.create.shipment.country.options.recipient = recipient_country_options;

      if (this.clipboard) {
        let clipboard = JSON.parse(this.clipboard);
        let label_type = Object.keys(clipboard)[0];
        let order = clipboard[label_type];
        let address = {
          sender: this.address.sender.default, 
          recipient: order.shipping_address
        };
        if (!address.sender) {
          let user = JSON.parse(BPA.storage.getItem('user'));
          let company = BPA.company('name', user.company_id);
          address.sender = this.address.options.find(option => new RegExp(`^${company}$`, 'i').test(option.first_name)) || {};
        }
        let country_shipping = this.country.shipping;
        let shipping_agent = this.shipping.option.agent;
        let shipping_method = this.shipping.option.method;
        let shipping_service = this.modal.create.shipment.service;
        let shipping_descriptions = await this.GetShippingMethods();
        let country_options = BPA.api.Countries('GET').map(country => ({
          id: country.id, code: country.iso_code_2, label: country.name
        })).filter(country => this.country.codes.includes(country.code));
        
        let agent = shipping_agent.find(option => option.code == order.shipping_courier);
        if (!agent && order.shipping_courier == 'shop') {
          order.shipping_description = 'GLS - Erhvervsadresse';
          agent = shipping_agent.find(option => option.code == 'gls');
          address.recipient.first_name = order.billing_address.first_name;
          address.recipient.last_name = order.billing_address.last_name;
        }
        let country = country_options.find(option => option.label == address.recipient.country);
        let sender_recipient = {shipping: `DK_${country.code}`, return: `${country.code}_DK`}[label_type];
        if (sender_recipient == 'NO_DK') {
          sender_recipient = 'NO_NO';
          country = country_options.find(option => option.code == 'NO');
          address.sender = this.address.options.find(option => option.first_name == 'Posten');
        }
        let shipment = {agent, country};
        let abroad_shipment = /^DK/.test(sender_recipient) && !/DK$/.test(sender_recipient);
        let country_shipping_agents = country_shipping[sender_recipient] || {};
        let country_shipping_methods = country_shipping_agents[agent.code] || [];
        let shipping_description = shipping_descriptions.find(option => option.shipping_method == order.shipping_description);
        let shipping_description_type = shipping_description.type.trim();
        let shipping_method_options = (shipping_method[agent.code] || []).filter(option => country_shipping_methods.includes(option.code));

        shipment.sender_country = address.sender.res_country_id || address.sender.country;
        let sender_country = {type: 'res_country_id' in address.sender ? 'id' : 'label', value: shipment.sender_country};
        shipment.sender_country = country_options.find(option => option[sender_country.type] == sender_country.value);

        shipment.recipient_country = address.recipient.country || address.recipient.res_country_id;
        let recipient_country = {type: 'res_country_id' in address.recipient ? 'id' : 'label', value: shipment.recipient_country};
        shipment.recipient_country = country_options.find(option => option[recipient_country.type] == recipient_country.value);

        if (!country_shipping_methods.length) {
          this.$eventHub.$emit('ShowMessages', {
            message: `No ${label_type} method with ${agent.label} from ${shipment.sender_country.label} to ${shipment.recipient_country.label}`,
            type: 'error',
            hide: 5000
          });
        } else {
          Object.assign(shipment, {method: (() => {
            for (let option of shipping_method_options) {
              if (label_type == 'shipping') {
                switch (shipping_description_type) {
                  case 'pakkeshop': {
                    switch (option.code) {
                      case 'shop': {
                        let bulk_option = shipping_method_options.find(option => /bulk/.test(option.code));
                        if (bulk_option) return bulk_option;
                        return option;
                      }
                      case 'shop_bulk': return option;
                      case 'parcel_shops': return option;
                      case 'collect_in_store': return option;
                    }
                    break;
                  }
                  case 'commercial': {
                    switch (option.code) {
                      case 'private': {
                        let customs_option = shipping_method_options.find(option => /customs/.test(option.code));
                        if (customs_option && abroad_shipment) return customs_option;
                        return option;
                      }
                      case 'private_with_customs_declaration': return option;
                      case 'direkte': {
                        let abroad_option = shipping_method_options.find(option => /udland/.test(option.code));
                        if (abroad_option && abroad_shipment) return abroad_option;
                        return option;
                      }
                      case 'direkte_udland': return option;
                      case 'parcel': return option;
                      case 'express': return option;
                      case 'instahome': return option;
                    }
                    break;
                  }
                  case 'business_address': {
                    switch (option.code) {
                      case 'business': return option;
                      default: {
                        console.log(option)
                      }
                    }
                  }
                }
              } else if (label_type == 'return') {
                switch (option.code) {
                  case 'shop_return': {
                    let bulk_option = shipping_method_options.find(option => /bulk/.test(option.code));
                    if (bulk_option) return bulk_option;
                    return option;
                  }
                  case 'shop_return_bulk': return option;
                  case 'return': return option;
                  case 'return_standalone': return option;
                }
              }
            }
          })()});
          if (!shipment.method) {
            this.$eventHub.$emit('ShowMessages', {
              message: `No ${label_type} method with ${agent.label}`,
              type: 'error',
              hide: 5000
            });
          } else {
            shipment.sender_first_name = address.sender.first_name;
            shipment.sender_last_name = address.sender.last_name;
            if (!shipment.sender_last_name && shipment.sender_first_name.split(' ').length > 1) {
              let first_name = shipment.sender_first_name.split(' ');
              let last_name = first_name.pop();
              shipment.sender_first_name = first_name.join(' ');
              shipment.sender_last_name = last_name;
            }
            shipment.sender_name = (shipment.sender_first_name + ' ' + shipment.sender_last_name).replace(/\s+/g, ' ').trim();
            shipment.sender_street = address.sender.address || address.sender.street_address;
            shipment.sender_zipcode = address.sender.zipcode || address.sender.zip_code;
            shipment.sender_city = address.sender.city;
            let sender_zipcode_city = (shipment.sender_zipcode + ' ' + shipment.sender_city).replace(/\s+/g, ' ').trim();
            shipment.sender_address = (shipment.sender_street + (sender_zipcode_city ? ', ' + sender_zipcode_city : '')).replace(/\s+/g, ' ').trim();
            shipment.sender_email = address.sender.email;
            shipment.sender_vat_no = address.sender.vat_no || address.sender.vat_number;
            shipment.sender_phone = address.sender.phone || address.sender.phone_number;
            shipment.recipient_first_name = address.recipient.first_name;
            shipment.recipient_last_name = address.recipient.last_name;
            if (!shipment.recipient_last_name && shipment.recipient_first_name.split(' ').length > 1) {
              let first_name = shipment.recipient_first_name.split(' ');
              let last_name = first_name.pop();
              shipment.recipient_first_name = first_name.join(' ');
              shipment.recipient_last_name = last_name;
            }
            shipment.recipient_name = (shipment.recipient_first_name + ' ' + shipment.recipient_last_name).replace(/\s+/g, ' ').trim();
            shipment.recipient_street = address.recipient.street_address || address.recipient.address;
            shipment.recipient_zipcode = address.recipient.zip_code || address.recipient.zipcode;
            shipment.recipient_city = address.recipient.city;
            let recipient_zipcode_city = (shipment.recipient_zipcode + ' ' + shipment.recipient_city).replace(/\s+/g, ' ').trim();
            shipment.recipient_address = (shipment.recipient_street + (recipient_zipcode_city ? ', ' + recipient_zipcode_city : '')).replace(/\s+/g, ' ').trim();
            shipment.recipient_email = address.recipient.email;
            shipment.recipient_vat_no = address.recipient.vat_number || address.recipient.vat_no;
            shipment.recipient_phone = address.recipient.phone_number || address.recipient.phone;
            shipment.recipient_company = address.recipient.company || address.recipient.att;
            shipment.refference = /*order.company_id + ':' +*/ order.increment_id;

            if (/pakkeshop/i.test(shipment.recipient_address)) {
              let parcel_shop_id = shipment.recipient_address.match(/pakkeshop:\s\w+/im)[0].trim();
              shipment.parcelshop_id = String(parcel_shop_id).replace(/pakkeshop:\s/i, '');
              shipment.parcelshop_address = shipment.recipient_address.replace(/^(.*?)\spakkeshop:\s\w+(.*?)$/i, '$1$2');
              shipment.recipient_address = shipment.recipient_address.replace(/^(.*?)\spakkeshop:\s\w+(.*?)$/i, '$1');
              shipment.recipient_street = shipment.recipient_street.replace(parcel_shop_id, '').trim();
              shipment.parcelshop_name = address.recipient.company;
            }

            if (/pakkeshop/i.test(shipment.sender_address)) {
              let parcel_shop_id = shipment.sender_address.match(/pakkeshop:\s\w+/im)[0].trim();
              shipment.sender_address = shipment.sender_address.replace(` ${parcel_shop_id}`, '').trim();
              shipment.sender_street = shipment.sender_street.replace(parcel_shop_id, '').trim();
            }

            if (shipment.agent.code == 'pdk') {
              shipping_service.pdk.options = await this.GetPostNordServiceOptions() || [];
            }

            let definition = settings[shipment.agent.code][shipment.method.code].definition;
            let args = {};

            for (let key in definition) {
              let value = "";
              let item = definition[key];
              let type = item.type.class.split("'")[1];
              if (type == 'int') value = 0;
              if (type == 'float') value = 0.0;
              if (type == 'list') value = [];
              if (item.type.nullable) value = null;
              if (/weight/.test(key)) {
                let weight = this.CloneObject(this.modal.create.shipment.weight);
                let options = /kg/.test(item.widget) ? weight.kg : weight.g;
                let previous = {code: 0};
                let order_weight = order.weight;
                if (!/kg/.test(item.widget)) order_weight /= 100;
                for (let option of options) {
                  if (previous.code <= order_weight && order_weight <= option.code) {
                    weight = option.code;
                    break;
                  }
                  previous.code = option.code;
                }
                value = weight;
              } else if (/ref/.test(key)) {
                value = shipment.refference;
              } else if (/name/.test(key)) {
                if (/sender/.test(key)) {
                  if (/^name|first/.test(key)) {
                    value = shipment.sender_first_name;
                  } else if (/last/.test(key)) {
                    value = shipment.sender_last_name;
                  } else if (/shop/.test(key)) {
                    value = shipment.parcelshop_name;
                  } else {
                    value = shipment.sender_name;
                  }
                } else {
                  if (/^name|first/.test(key)) {
                    value = shipment.recipient_first_name;
                  } else if (/last/.test(key)) {
                    value = shipment.recipient_last_name;
                  } else if (/shop/.test(key)) {
                    value = shipment.parcelshop_name;
                  } else {
                    value = shipment.recipient_name;
                  }
                }
                if (!/shop/.test(key)) {
                  value = this.TitleCase(value);
                }
              } else if (/tlf|phone/.test(key)) {
                if (/sender/.test(key)) {
                  value = shipment.sender_phone;
                } else {
                  value = shipment.recipient_phone;
                }
              } else if (/email/.test(key)) {
                if (/sender/.test(key)) {
                  value = shipment.sender_email;
                } else {
                  value = shipment.recipient_email;
                }
                value = (value).toLowerCase();
              } else if (/vat_no/.test(key)) {
                if (/sender/.test(key)) {
                  value = shipment.sender_vat_no;
                } else {
                  value = shipment.recipient_vat_no;
                }
              } else if (/company/.test(key)) {
                if (/sender/.test(key)) {
                  value = shipment.sender_company;
                } else {
                  value = shipment.recipient_company;
                }
              } else if (/address/.test(key)) {
                if (/sender/.test(key)) {
                  if (!/2/.test(key)) {
                    value = shipment.sender_street;
                  }
                } else if (!/2/.test(key)) {
                  if (/shop/.test(key)) {
                    value = shipment.parcelshop_address;
                  } else if (shipment.parcelshop_address) {
                    value = shipment.recipient_address;
                  } else {
                    value = shipment.recipient_street;
                  }
                }
                value = this.TitleCase(value);
              } else if (/pickup|point|shop/.test(key)) {
                if (/id/.test(key)) value = shipment.parcelshop_id;
              } else if (/zip|postal/.test(key)) {
                if (/send/.test(key)) {
                  value = shipment.sender_zipcode;
                } else {
                  value = shipment.recipient_zipcode;
                }
              } else if (/city/.test(key)) {
                if (/sender/.test(key)) {
                  value = shipment.sender_city;
                } else {
                  value = shipment.recipient_city;
                }
                value = this.TitleCase(value);
              } else if (/country|iso/.test(key)) {
                if (/sender/.test(key)) {
                  if (/id|num/.test(key)) {
                    value = shipment.sender_country.id;
                  } else {
                    value = shipment.sender_country.code;
                  }
                } else {
                  if (/id|num/.test(key)) {
                    value = shipment.recipient_country.id;
                  } else {
                    value = shipment.recipient_country.code;
                  }
                }
              } else if (/customer_number/.test(key)) {
                let country_code = shipment.recipient_country.code;
                let customer_numbers = await this.GetBringCustomerNumbers() || {};
                if (country_code in customer_numbers) {
                  let options = customer_numbers[country_code];
                  if (options.length) value = options[0].code;
                }
              } else if (/service|addons/.test(key)) {
                if (/service/.test(key)) {
                  if (shipment.agent.code == 'pdk') {
                    let country_code = shipment.recipient_country.code;
                    let services = shipping_service.pdk.options || [];
                    let service_countries = shipping_service.pdk.countries || {};
                    let options = [];
                    if (/pickup/.test(item.widget)) {
                      options = ['P19DK', 'P19DKBP', 'P19DKDPD'];
                    }
                    if (/private/.test(item.widget)) {
                      options = ['PDK17', 'PDK17BP', 'PDK17WORLD', 'PDK17DPD'];
                    }
                    options = options.filter(option => service_countries[option].includes(country_code));
                    shipment.services = services.filter(service => options.includes(service.code));
                    if (shipment.services.length == 1) {
                      value = shipment.services[0].code;
                    }
                  }
                } else if (/addons/.test(key)) {
                  if (shipment.agent.code == 'pdk') {
                    if (shipment.services && shipment.services.length == 1) {
                      let service = shipment.services[0];
                      if (service.code ==  'PDK17WORLD') {
                        let addon = service.addons.find(addon => addon.code == 'ECONOMY');
                        if (addon) value = [{id: addon.code}];
                      } else {
                        let addon = service.addons.find(addon => addon.code == 'NOTEMAIL');
                        if (addon) value = [{id: addon.code}];
                      }
                    }
                  }
                }
              } else if (/currency/.test(key)) {
                value = order.currency_iso || '';
              } else if (/freight/.test(key)) {
                value = Number(order.shipping_handling || 0).toFixed(2);
              } else if (/lines/.test(key)) {
                let lines = [];
                for (let item of order.ordered_items) {
                  lines.push({
                    copies: item.qty || 0,
                    sourceCountryCode: (country_options.find(option => option.id == item.country_id) || {}).code || '',
                    contents: item.product || '',
                    statNo: item.tarif || '',
                    netWeight: Number(item.weight || 0).toFixed(2),
                    value: Number(item.price || 0).toFixed(2),
                    valuesPerItem: true
                  });
                }
                value = lines;
              }
              args[key] = value;
            }
            
            await this.OpenCreateShipmentFormModal(shipment, label_type == 'return', {
              agent_code: shipment.agent.code,
              method: shipment.method.code,
              args: {args}
            });
          }
        }
      } else {
        this.cached = BPA.cache.local({name: this.$options.name, get: ['page', 'search', 'shipping', 'date']});
        for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
        this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'shipment'})};
      }

      this.search.input = document.querySelector('.grid-search > input');
      this.search.selects = Array.from(document.querySelectorAll('.grid-search-filter .v-select input'));

      // Get list of shipments
      this.QueryLabels();
    },
    destroyed() {},
    computed: {
      main() {
        const main = {
          company: BPA.util.GetCompany(),
          view: document.querySelector('.content-scroll')
        }
        main.view.scroll = {top: 0};
        return main;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        //this.state = this.$t(this.Capitalize(this.search.state));
      }
    },
    methods: {
      DateFormat(date) {
        if (date) return Tool.DateFormat(date);
      },
      DateToISO(date) {
        if (date) return Tool.DateToISO(date);
      },
      DateToUTC(date) {
        if (date) return Tool.DateToUTC(date);
      },
      DateOnly(date) {
        date = date + ' 00:00:00';
        date = Tool.DateFormat(date).replace(Tool.TimeOnly(date), '');
        return date.replace(/,(?=[^,]*$)/, '');
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      CurrencyFormat(number, minmax) {
        if (number) return Tool.CurrencyFormat(number, null, minmax);
      },
      CurrencyName(currency_code) {
        return Tool.CurrencyName(currency_code);
      },
      CountryName(country_code) {
        return Tool.CountryName(country_code);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      CloneObject(object = {}) {
        return JSON.parse(JSON.stringify(object));
      },
      Capitalize(string = '') {
        return String(Tool.Capitalize(string));
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      Trim(e) {
        if (typeof e == 'string') {
          return String(e).trim();
        }
        if (typeof e == 'object' && e.target) {
          const element = e.target;
          const prop = 'value' || 'textContent';
          const text = element[prop];
          element[prop] = String(text).trim();
          return element[prop];
        }
      },
      CapitalizeFirstLetter(e) {
        if (typeof e == 'string') {
          return String(e.charAt(0).toUpperCase() + e.slice(1)).replace(/\s\s+/g, ' ');
        }
        if (typeof e == 'object' && e.target) {
          const element = e.target;
          const prop = 'value' || 'textContent';
          const text = element[prop];
          element[prop] = String(text.charAt(0).toUpperCase() + text.slice(1)).replace(/\s\s+/g, ' ');
          return element[prop];
        }
      },
      RandomString(length = 5) {
        return Tool.RandomString(length);
      },
      Hyperlink(props = {}) {
        if (props.scheme == 'tel') {
          if (!props.href.startsWith('+')) {
            if (props.country) {
              props.href = BPA.phone(props.country) + props.href;
              delete props.country;
            }
          }
        }
        return Tool.Hyperlink(props);
      },
      TrackingURL(params = {}) {
        params.company = params.company || this.main.company;
        return Tool.TrackingURL(params);
      },
      async SearchByCompany(option) {
        let company_id = option ? option.id : '';
        //await this.UpdateShippingAgents(company_id);
        this.search.company = company_id;
        this.page.current = 1;
        this.QueryLabels();
      },
      SearchByCountry(option) {
        option = option ? option : {};
        this.search.country = option.id || '';
        this.page.current = 1;
        this.QueryLabels();
      },
      SearchByShippingAgent(option) {
        option = option ? option : {};
        this.shipping.agent = option;
        this.search.agent = option.code || '';
        this.search.method = null;
        this.shipping.method = null;
        this.page.current = 1;
        this.QueryLabels();
      },
      SearchByShippingMethod(option) {
        option = option ? option : {};
        this.shipping.method = option;
        this.search.method = option.code || '';
        this.page.current = 1;
        this.QueryLabels();
      },
      SearchByDate(date) {
        const QueryLabels = () => {
          this.page.current = 1;
          this.QueryLabels();
        }
        if (!date) {
          let clear = {start : '', end: ''};
          this.search.date = clear;
          this.date = clear;
          return QueryLabels();
        }
        if (!date.start || !date.end) return;
        this.search.date = {
          start: this.DateToUTC(date.start),
          end: this.DateToUTC(date.end)
        }
        QueryLabels();
      },
      Search() {
        this.search.input.blur();
        this.search.query = this.search.query.replace(/\s\s+/g, ' ').trim();
        if (this.search.query == this.search.initial) return;
        this.search.auto_company = true;
        this.page.current = 1;
        this.QueryLabels();
      },
      SetOrderBy(value) {
        if (this.page.order.by == value) {
          if (this.page.order.direction == 'desc') {
            this.page.order.direction = 'asc';
          } else {
            this.page.order.direction = 'desc';
          }
        }
        this.page.order.by = value;
        this.QueryLabels();
      },
      async QueryLabels() {
        this.loading = true;
        const request = {
          query: this.search.query,
          company_id: this.search.company,
          agent_code: this.search.agent,
          country_id: this.search.country,
          method: this.search.method,
          create_date_from: this.search.date.start,
          create_date_to: this.search.date.end,
          order_by: this.page.order.by + ' ' + this.page.order.direction,
          page_offset: this.page.current
        }
        const agent_options = this.shipping.option.agent;
        const method_options = this.shipping.option.methods;
        const agent_methods = this.shipping.option.method;
        const data = await this.GetLabels(request);
        if (!data) return this.loading = false;
        if (!data.page_offset) data.page_offset = 0;
        this.search.initial = this.search.query;
        this.page.current = data.page_offset + 1;
        this.page.last = data.page_total || 1;
        this.entries = data.item_total || data.items.length;
        const shipments = this.CloneObject(data.items);
        for (let shipment of shipments) {
          let country = {};
          let shipping = {agent: {}, method: {}};
          const ref = String(shipment.ref).toLowerCase();
          const recipient = /return/.test(ref) ? 'sender' : 'customer';
          for (let option of this.country.options) {
            if (option.id == shipment[`${recipient}_res_country_id`]) {
              country = option; break;
            }
          }
          for (let option of agent_options) {
            if (option.code == shipment.agent_code) {
              shipping.agent = option; break;
            }
          }
          for (let option of method_options) {
            if (option.agent == shipment.agent_code && option.code == shipment.method) {
              shipping.method = option; break;
            }
          }
          shipment.country = {
            id: country.id,
            code: country.code,
            name: country.label
          }
          let method_is_not_return_or_customs_declaration = !/return|customs/.test(shipping.method.code);
          let agent_has_a_return_method = agent_methods[shipping.agent.code].some(o => /return/.test(o.code));
          shipment.returnable = method_is_not_return_or_customs_declaration && agent_has_a_return_method;
          shipment.agent = shipping.agent;
          shipment.method = shipping.method;
          delete shipment[`${recipient}_res_country_id`];
          delete shipment.agent_code;
          const recipient_name_key = `${recipient}_name`;
          const recipient_address_key = `${recipient}_address`;
          const recipient_name = shipment[recipient_name_key];
          let recipient_address = shipment[recipient_address_key] || '';
          recipient_address = recipient_address.replace(new RegExp(recipient_name), '');
          shipment[recipient_address_key] = recipient_address;
          let postal = this.postal[shipment.country.code] || {};
          //if (!postal) postal = await this.GetPostalCodeCity(shipment.country.code);
          shipment.postal_code = Object.keys(postal).find(code => recipient_address.includes(code));
          shipment.city = postal[shipment.postal_code];
          shipment.action = shipment.opcode;
          delete shipment.opcode;
          const type_code = [];
          const ref_array = [];
          let handlebar = '';
          for (let code of ['manual', 'recreate', 'return']) {
            if (ref.includes(code)) type_code.push(code);
          }
          shipment.ref.split(/[{}]/).slice(1).map(word => {
            if (!handlebar && !word.length) {
              handlebar = '{{';
            } else if (handlebar && word.length) {
              handlebar += word;
            } else if (handlebar && !word.length) {
              ref_array.push(handlebar + '}}');
              handlebar = '';
            } else {
              ref_array.push(word);
            }
          });
          shipment.type_code = type_code;
          shipment.type = ref_array;
          delete shipment.ref;
        }
        this.shipments = shipments;
        this.loading = false;
        BPA.cache.local({name: this.$options.name, set: {page: this.page, search: this.search, shipping: this.shipping, date: this.date}});
        this.$nextTick().then(() => {
          this.clickables = document.querySelectorAll('.clickable');
          if (this.cached.shipment && Object.keys(this.cached.shipment).length) {
            this.OpenLabel(this.cached.shipment.id);
          }
          this.SetPageJumpWidth();
          //this.onresize();
        });
      },
      async GetLabels(request) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.GetCustomLabels(request).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetLabel(shipment_id) {
        return await new Promise((resolve, reject) => {
          if (!shipment_id) return reject();
          BPA.api.GetCustomLabel(shipment_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetLabelArgs(shipment_id) {
        return await new Promise((resolve, reject) => {
          if (!shipment_id) return reject();
          BPA.api.GetCustomLabelCreateArgs(shipment_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async OpenLabel(shipment_id) {
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].dataset.id == shipment_id) {
            this.clickables[i].classList.add('selected');
            if (this.cached.shipment && Object.keys(this.cached.shipment).length) {
              this.clickables[i].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
            }
            break;
          }
        }
        this.loading = true;
        const agent_options = this.shipping.option.agent;
        const method_options = this.shipping.option.methods;
        const agent_methods = this.shipping.option.method;
        const data = await this.GetLabel(shipment_id);
        const removeLastPart = str => String(str.substring(0, str.lastIndexOf(',')) || str).trim();
        const getLastPart = str => String(str.substring(str.lastIndexOf(',') + 1) || str).trim();
        const ref = String(data.ref).toLowerCase();
        const shipment = {id: data.id};
        const type_code = [];
        const ref_array = [];
        let handlebar = '';
        let address = {street: '', postal: '', city: ''};
        let country = {sender: {}, recipient: {}};
        let shipping = {agent: {}, method: {}};
        for (let option of this.country.options) {
          if (option.id == data.sender_res_country_id.id) country.sender = option;
          if (option.id == data.customer_res_country.id) country.recipient = option;
          if (Object.keys(country.sender).length && Object.keys(country.recipient).length) break;
        }
        for (let option of agent_options) {
          if (option.code == data.agent_code) {
            shipping.agent = option; break;
          }
        }
        for (let option of method_options) {
          if (option.agent == data.agent_code && option.code == data.method) {
            shipping.method = option; break;
          }
        }
        for (let country_code of [country.sender.code, country.recipient.code]) {
          await this.GetPostalCodeCity(country_code);
        }
        if ('parcelshop_name' in data) {
          let name = data.parcelshop_name;
          let addr = data.parcelshop_address;
          let street = removeLastPart(addr);
          let last_part = getLastPart(addr);
          let postal = this.postal[country.recipient.code] || {};
          let code = Object.keys(postal).find(code => new RegExp(code).test(last_part));
          let city = postal[code];
          let postal_city = new RegExp(`(${code}?)${String(city).split(' ').map(e => `|(${e}?)`).join('')}$`, 'gi');
          street = addr.replace(name, '').replace(postal_city, '').replace(',,', ',').trim() || '';
          if (/pakkeshop/i.test(street) && (street.match(/pakkeshop/ig) || []).length > 1) {
            let address_street = street.replace(/^(.*?)(pakkeshop:\s\w+)(.*?)$/im, '$1').trim();
            let parcel_shop = street.match(/pakkeshop:\s\w+/im)[0].trim();
            street = address_street + ' ' + parcel_shop;
          }
          shipment.shop = {
            name: name,
            address: {
              string: data.parcelshop_address,
              street: street.replace(/,$/g, '') || '',
              postal: code || '',
              city: city || ''
            }
          }
        }
        const create_role_data = (role) => {
          let key = role == 'recipient' ? 'customer' : role;
          shipment[role] = {
            name: data[`${key}_name`],
            address: this.CloneObject(address),
            phone: data[`${key}_phone_number`],
            email: data[`${key}_email`],
            country: {
              id: country[role].id,
              code: country[role].code,
              //name: country[role].label,
              name: this.CountryName(country[role].code)
            }
          }
          let name = shipment[role].name;
          let addr = data[`${key}_address`];
          let street = removeLastPart(addr);
          let last_part = getLastPart(addr);
          let postal = this.postal[country[role].code] || {};
          let code = Object.keys(postal).find(code => new RegExp(code).test(last_part));
          let city = postal[code];
          let postal_city = new RegExp(`(${code}?)${String(city).split(' ').map(e => `|(${e}?)`).join('')}$`, 'gi');
          street = addr.replace(name, '').replace(postal_city, '').replace(',,', ',').trim() || '';
          shipment[role].address.string = data[`${key}_address`];
          shipment[role].address.street = street.replace(/,$/g, '') || '';
          shipment[role].address.postal = code || '';
          shipment[role].address.city = city || '';
        }
        // Create shipment role based data object
        ['sender', 'recipient'].map(create_role_data);
        let method_is_not_return_or_customs_declaration = !/return|customs/.test(shipping.method.code);
        let agent_has_a_return_method = agent_methods[shipping.agent.code].some(o => /return/.test(o.code));
        shipment.returnable = method_is_not_return_or_customs_declaration && agent_has_a_return_method;
        shipment.agent = shipping.agent;
        shipment.method = shipping.method;
        shipment.user = data.res_users;
        shipment.date = data.create_date;
        shipment.reference = data.customer_ref;
        shipment.company = data.res_company;
        shipment.action = this.Capitalize(data.opcode);
        shipment.tracking = data.tnt;
        for (let code of ['manual', 'recreate', 'return']) {
          if (ref.includes(code)) type_code.push(code);
        }
        data.ref.split(/[{}]/).slice(1).map(word => {
          if (!handlebar && !word.length) {
            handlebar = '{{';
          } else if (handlebar && word.length) {
            handlebar += word;
          } else if (handlebar && !word.length) {
            ref_array.push(handlebar + '}}');
            handlebar = '';
          } else {
            ref_array.push(word);
          }
        });
        shipment.type_code = type_code;
        shipment.type = ref_array;
        shipment.original = data;

        BPA.cache.session({name: this.$options.name, set: {shipment}});

        this.shipment = shipment;

        //this.modal.view.shipment.title = 'ID: ' + shipment.id + ' - ' + this.$t('Created by') + ' ' + shipment.user.name + ', ' + this.DateFormat(shipment.date);
        this.modal.view.shipment.title = `<span>#${shipment.id}: ${shipment.user.name}</span><span>${this.DateFormat(shipment.date)}</span>`;
        this.loading = false;
        this.OpenModal('view.shipment');
      },
      async PrintPDF(shipment) {
        //if (!this.printer.options.length) {
          this.loading = true;
          await this.RefreshPrinterOptions();
          this.loading = false;
        //}
        const confirmed = await new Promise(resolve => {
          this.popup.action = 'print';
          this.popup.show = true;
          let selected_printer = this.printer.selected;
          this.popup.button.cancel.click = () => {
            this.printer.selected = selected_printer;
            this.popup.action = '';
            resolve(false);
          }
          this.popup.button.confirm.click = () => {
            this.popup.show = false;
            this.popup.action = '';
            resolve(true);
          }
        });
        if (!confirmed) return;
        this.PrintShippingLabel({
          shipment_id: shipment.id,
          printer_id: this.printer.selected.id
        });
      },
      async OpenPDF(shipment_id) {
        const blob = await this.GetPdfAsBlob(shipment_id);
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
        URL.revokeObjectURL(blobURL);
      },
      async DownloadPDF(shipment) {
        const blob = await this.GetPdfAsBlob(shipment.id);
        let is_return = shipment.type_code.includes('return');
        if (blob) {
          let reference = shipment.reference;
          let title = `[${is_return ? 'RETURN' : 'SHIPPING'} LABEL] ` + shipment.method.label + (reference ? ' (' + reference + ') - ' : ' ') + shipment.tracking;
          BPA.api.download({blob: blob, name: title, new_tab: BPA.browser == 'firefox'});
        }
      },
      async GetPdfAsBlob(shipment_id) {
        return await new Promise((resolve, reject) => {
          if (!shipment_id) return reject();
          this.loading = true;
          BPA.api.DownloadCustomLabel(shipment_id).then(response => {
            return BPA.api.response({response, 
              return: () => {
                return response.arrayBuffer();
              }
            });
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return reject();
            let arrayBuffer = response.result || {};
            resolve(new Blob([arrayBuffer], {type: 'application/pdf'}));
          }).catch(reject);
        }).catch(e => e);
      },
      async CancelLabel(agent, tracking) {
        return await new Promise((resolve, reject) => {
          if (!agent || !tracking) return reject();
          BPA.api.CancelLabel({
            agent_code: agent, 
            barcode: tracking
          }).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(true);
          }).catch(reject);
        }).catch(e => e);
      },
      async CancelShipment(item) {
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, cancel it',
          disapprove: 'No',
          message: 'Cancels the current shipment.',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          let agent = item.agent.code;
          let tracking = item.tnt || item.tracking;
          const cancelled = await this.CancelLabel(agent, tracking);
          this.loading = false;
          if (cancelled) this.$eventHub.$emit('ShowMessages', {
            message: 'The shipment is now cancelled',
            type: 'success',
            hide: 2000
          });
        });
      },
      async GetShippingMethods(company_code) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetShippingOptions(company_code).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return;
            let options = response.result || [];
            resolve(options);
          }).catch(reject);
        }).catch(e => e);
      },
      SelectPhonePrefix(field, option) {
        const shipment = this.modal.create.shipment;
        const form = shipment.form.data;
        const name = field.name;
        let value = field.value;
        let prefix = option ? option.code : '';
        let prefix_regex = prefix ? new RegExp(prefix.replace('+', '\\+')) : '';
        let prefix_option = field.options.find(option => new RegExp(`^${option.code.replace('+', '\\+')}`).test(value));
        if (prefix_option) prefix_regex = new RegExp(prefix_option.code.replace('+', '\\+'));
        value = String(value).replace(prefix_regex, '');
        value = value.replace(/(\s)?[^\d()+-]/ig, '');
        form[name] = value ? prefix + value : '';
        field.selected = option || '';
        field.value = value || '';
      },
      SelectPhoneType(field, option) {
        console.log(field, option)
      },
      Modal(name) {
        return (name.includes('.') ? name.split('.').reduce((o, i) => o[i], this.modal) : this.modal[name] || this.modal || this[name]) || {};
      },
      async OpenModal(modal_name) {
        if (modal_name == 'create.shipment') {
          return await this.InitShipmentSetup(true).then(() => {
            this.Modal(modal_name).open = true;
            this.$nextTick().then(() => {
              let shipment = this.modal.create.shipment;
              let country_select = shipment.country.select;
              for (let role of ['sender', 'recipient']) {
                country_select[role].elm = this.$refs[`country_${role}`].$el;
                country_select[role].spinner = country_select[role].elm.querySelector('.vs__spinner');
              }
              if (shipment.setup.option.agent.length > 1) {
                let agent_select = this.$refs.shipping_agent;
                if (agent_select) setTimeout(() => {
                  agent_select.$refs.search?.focus();
                }, 100);
              }
            });
          });
        }
        this.Modal(modal_name).open = true;
      },
      SetActiveModalTab(modal_name, index) {
        if (modal_name == 'create.shipment') return;
        this.Modal(modal_name).tab.active = index;
        modal_name = modal_name.replace(/(\.|_)/g, '-');
        const modal = document.getElementById(modal_name);
        modal && modal.querySelectorAll('ul.modal-tabs__body').forEach(ul => ul.scrollTop = 0);
      },
      async GetPostalCodeCity(country_code) {
        return await new Promise((resolve, reject) => {
          if (this.postal.hasOwnProperty(country_code)) {
            return resolve(this.postal[country_code]);
          }
          return BPA.api.GetPostalCodes(country_code).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return;
            let data = {};
            let result = response.result || [];
            for (let i = 0; i < result.length; i++) {
              data[result[i].zipcode] = result[i].city;
            }
            if (/at/.test(country_code)) {
              data[5101] = 'Bergheim';
              data[6372] = 'Oberndorf in Tirol';
            }
            if (/lu/.test(country_code)) {
              data[1273] = 'Luxembourg';
            }
            this.postal[country_code] = data;
            return resolve(this.postal[country_code]);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetBringCustomerNumbers() {
        return new Promise((resolve, reject) => {
          BPA.api.GetBringCustomerNumbers().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return;
            let data = {};
            let options = response.result || [];
            for (let i = 0; i < options.length; i++) {
              let option = options[i];
              if (!data.hasOwnProperty(option.countryCode)) {
                data[option.countryCode] = [];
              }
              data[option.countryCode].push({
                code: option.customerNumber,
                products: option.products,
                title: option.products.join('\n'),
                label: option.name
              });
            }
            resolve(data);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetCountryShippingAgentMethods() {
        return new Promise((resolve, reject) => {
          window.fetch(`../shipping.json?${new Date().getTime()}`).then(response => {
            if (response && response.status == 200) return response.json();
          }).then(resolve).catch(reject);
        }).catch(e => e);
      },
      async GetPostNordServiceOptions() {
        return new Promise((resolve, reject) => {
          window.fetch(`../postnord.json?${new Date().getTime()}`).then(response => {
            if (response && response.status == 200) return response.json();
          }).then(json => {
            let data = [];
            let options = json || [];
            for (let i = 0; i < options.length; i++) {
              let option = options[i];
              data.push({
                code: option.code,
                label: option.label,
                title: option.label,
                addons: option.addons
              });
            }
            resolve(data);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetCurrencyOptions() {
        return new Promise((resolve, reject) => {
          window.fetch('../currencies.json?' + new Date().getTime()).then(response => {
            if (response && response.status == 200) return response.json();
          }).then(json => {
            let data = [];
            let options = json || [];
            for (let i = 0; i < options.length; i++) {
              let option = options[i];
              data.push({
                code: option.code,
                label: option.name,
                title: option.symbol,
                iso: option.code.substring(0, 2)
              });
            }
            resolve(data);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetAddressBook() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetAddressBook().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetAddressOptions() {
        return new Promise(async resolve => {
          let options = [];
          let book = await this.GetAddressBook() || [];
          for (let item of book) {
            item.name = item.first_name;
            item.name += ' ' + item.last_name;
            item.label = String(item.name).trim();
            options.push(item);
          }
          resolve(options);
        });
      },
      async RefreshAddressOptions(role = 'sender', init) {
        let shipment = this.modal.create.shipment;
        let address = this.address;
        let sender = address.sender;
        if (!address.initialized) {
          address.initialized = !!init;
          address.loading = true;
          address.options = await this.GetAddressOptions();
          address.loading = false;
        }
        let country_role = role;
        let country = shipment.country;
        let country_roles = ['sender', 'recipient'];
        let return_label = /return/.test(shipment.shipping.method.code);
        /*
        let abroad_shipment = country.sender.code != country.recipient.code;
        if (return_label && abroad_shipment) {
          country_role = country_roles.find(role => role != country_role);
        }
        */
        if (return_label) {
          country_role = country_roles.find(role => role != country_role);
        }
        let country_id = country[country_role].id;
        let address_role = address[country_role];
        let address_options = this.CloneObject(address.options);
        address_options = address_options.filter(option => option.res_country_id == country_id);
        if (address_role.selected && address_role.selected.id) {
          if (!address_options.some(option => option.id == address_role.selected.id)) {
            return this.SelectAddress(country_role);
          }
        }
        if (sender.default && sender.default.id) {
          if (shipment.id) { // this.modal.create.mode == 'recreate'
            for (let tab of shipment.tab.page) {
              for (let group of tab.group) {
                for (let field of (group || {}).field || []) {
                  if (/address/.test(field.ref)) {
                    let field_role = field.ref.split('_')[0];
                    let address_option = address_options.find(option => option.address == field.value);
                    this.address[field_role].selected = address_option || '';
                    break;
                  }
                }
              }
            }
            return;
          }
          this.SelectAddress('sender', address_options.find(option => option.id == sender.default.id));
        }
      },
      SelectAddress(role = 'sender', selected = '') {
        this.address[role].selected = selected;
        let address_role = this.address[role];
        let shipment = this.modal.create.shipment;
        let tabs = shipment.tab.page;
        let form = shipment.form.data;
        let address = (address_role.selected && this.CloneObject(address_role.selected)) || {};
        let address_keys = ['name', 'first_name', 'last_name', 'att', 'phone', 'email', 'address', 'zipcode', 'city', 'vat_no', 'res_country_id'];
        let name_regex = new RegExp(`^(${role}_)?(first_)?(last_)?name$`);
        let address_fields = [];
        let tab_fields = [];
        if (Object.keys(address).length) {
          address.name = address_role.selected.label;
        }
        for (let tab of tabs) {
          for (let group of tab.group) {
            for (let field of (group || {}).field || []) {
              if (name_regex.test(field.ref)) {
                if (role != field.ref.split('_')[0]) break;
                tab_fields = [].concat.apply([], tab.group.map(item => item.field));
                address_fields = tab_fields.filter(item => {
                  return /name|att|phone|email|company|address|postal|city|vat_no|country/.test(item.ref) && !/address?(_type|2)/.test(item.ref);
                });
                break;
              }
            }
          }
        }
        for (let item of address_fields) {
          let name = item.name;
          let ref = item.ref;
          let key = '';
          switch (true) {
            case /first/.test(ref): key = 'first_name'; break;
            case /last/.test(ref): key = 'last_name'; break;
            case /name|company/.test(ref): key = 'name'; break;
            case /phone/.test(ref): key = 'phone'; break;
            case /att/.test(ref): key = 'att'; break;
            case /email/.test(ref): key = 'email'; break;
            case /address/.test(ref): key = 'address'; break;
            case /postal/.test(ref): key = 'zipcode'; break;
            case /city/.test(ref): key = 'city'; break;
            case /vat_no/.test(ref): key = 'vat_no'; break;
            case /country/.test(ref): key = 'res_country_id';
          }
          if (!address_keys.includes(key)) {
            console.log(key)
          }
          //console.log(key, address_keys, item.widget, item.value)
          if (address_keys.includes(key)) {
            if (/prefix/.test(item.widget)) {
              if (address[key]) {
                let value = String(address[key]).replace(/\s/g, '');
                let prefix = item.selected ? item.selected.code : '';
                let prefix_regex = prefix ? new RegExp(prefix.replace('+', '\\+')) : '';
                let prefix_option = item.options.find(option => new RegExp(`^${option.code.replace('+', '\\+')}`).test(value));
                if (!prefix_option) {
                  let country = shipment.country[role];
                  prefix_option = item.options.find(option => option.iso == country.code);
                }
                prefix = prefix_option.code;
                prefix_regex = new RegExp(prefix.replace('+', '\\+'));
                value = String(value).replace(prefix_regex, '');
                value = value.replace(/(\s)?[^\d()+-]/ig, '');
                form[name] = value ? prefix + value : '';
                item.selected = prefix_option;
                item.value = value || '';
              }
            } else if (/burd_coverage/.test(item.widget)) {
              let value = address[key];
              if (item.options.includes(value)) {
                item.value = value;
                form[name] = value;
              } else {
                item.value = '';
                delete form[name];
              }
            } else if (/phone_type/.test(item.widget)) {
              item.blur({target: {value: address[key]}});
            } else if (/country/.test(key)) {
              if (address[key]) {
                let country = address[key];
                item.value = this.country.options.find(option => option.id == country || option.code == country);
                form[name] = item.value[item.class == 'int' ? 'id' : 'code'];
              }
            } else {
              item.value = address[key];
              form[name] = address[key];
            }
          }
        }
        let address_book = this.$refs[`${role}_address_book`] || [];
        let address_refs = ['address', 'address2', 'postal_code', 'city'];
        let address_elements = address_refs.map(ref => this.$refs[`${role}_${ref}`]).filter(e => e).flat();
        if (address_book.length) address_book[0].classList.remove('visible');
        if (address_elements.length) {
          if (tab_fields.some(field => /shop_finder|sort_code/.test(field.widget))) {
            let postal_field = address_elements.find(field => /zip|postal/.test(field.name));
            if (postal_field) postal_field.dispatchEvent(new Event('blur'));
          }
        }
      },
      SetDefaultSenderAddress() {
        if (this.address.sender.selected) {
          BPA.util.SetSenderAddress(this.address.sender.selected);
          this.address.sender.default = this.address.sender.selected;
        }
      },
      RemoveDefaultSenderAddress() {
        BPA.storage.removeItem('senderAddress');
        this.address.sender.default = '';
      },
      GetDefaultSenderAddress() {
        const default_address = BPA.util.GetSenderAddress() || {};
        return Object.keys(default_address).length ? default_address : '';
      },
      fuseSearch(options, search) {
        const fuse = new Fuse(options, {keys: ['label', 'name'], shouldSort: true});
        return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list;
      },
      async GetPhoneCountryCodes() {
        return await new Promise((resolve, reject) => {
          window.fetch(`../phone.json?${new Date().getTime()}`).then(response => {
            if (response && response.status == 200) return response.json();
          }).then((phone_codes = {}) => {
            const options = [];
            const country_codes = Object.keys(phone_codes);
            const countries = this.country.options.map(o => o.code);
            for (let country_code of countries) {
              if (country_codes.includes(country_code)) {
                options.push({
                  code: phone_codes[country_code], 
                  label: phone_codes[country_code],
                  name: this.CountryName(country_code),
                  iso: country_code
                });
              }
            }
            resolve(options);
          }).catch(reject);
        });
      },
      async GetBurdCoverage() {
        return await new Promise((resolve) => {
          window.fetch('https://clubace.dk/get/burd/coverage.php').then(response => {
            if (response && response.status == 200) return response.json();
          }).then((postal_codes = []) => resolve(postal_codes));
        });
      },
      async GetBurdAvailability() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetBurdAvailability().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetInstaboxAvailability(request = {}) {
        return await new Promise((resolve, reject) => {
          let required = ['country_code', 'service_type', 'zipcode'];
          for (let key of required) if (!request[key]) return reject();
          BPA.api.GetInstaboxAvailability(request).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async HasResParcelConf(/*company_id*/) {
        //console.log(company_id)
        return await new Promise((resolve, reject) => {
          BPA.api.HasResParcelConf().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async UpdateShippingAgents(company_id) {
        if (!company_id) company_id = BPA.util.GetMainCompany().id;
        return await new Promise(async resolve => {
          let config = {
            agents: BPA.api.Couriers('GET'),
            methods: await this.HasResParcelConf(company_id),
          }
          let options = {agents: {}, methods: []};
          for (let code in config.agents) {
            if (config.methods.includes(code)) {
              options.agents[code] = config.agents[code];
              options.methods.push({code: code, label: config.agents[code]});
            }
          }
          options.methods.sort((a, b) => {
            a = a.label.toUpperCase(); 
            b = b.label.toUpperCase();
            return a < b ? -1 : a > b ? 1 : 0;
          });
          this.couriers = options.agents;
          this.courier.options = options.methods;
          resolve(true);
        }).catch(e => e);
      },
      async GetLabelGeneratorSetup(init) {
        return await new Promise((resolve, reject) => {
          if (this.GetLabelGeneratorSetup.result) {
            return resolve(this.GetLabelGeneratorSetup.result);
          }
          BPA.api.GetLabelGeneratorSetup().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!init) this.loading = false;
            if (!response.ok || !response.result) return reject();
            this.GetLabelGeneratorSetup.result = response.result;
            resolve(this.GetLabelGeneratorSetup.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async InitShipmentSetup(spinner) {
        return await new Promise(async resolve => {
          if (spinner) this.loading = true;
          const shipment = this.modal.create.shipment;
          const setup = shipment.setup;
          if (!setup.settings) {
            setup.settings = await this.GetLabelGeneratorSetup();
          }
          if (!setup.settings) return;
          const settings = setup.settings;
          const couriers = this.courier.options;
          if (!setup.option.agent.length) {
            for (let agent in settings) {
              let option = couriers.find(option => option.code == agent);
              if (option) setup.option.agent.push({
                code: agent,
                label: option.label
              });
              setup.option.method[agent] = [];
              for (let method in settings[agent]) {
                let method_option = {code: method, agent: agent};
                if (agent in settings && method in settings[agent]) {
                  method_option.label = settings[agent][method].name;
                }
                if (agent in shipment.subtitle) {
                  method_option.subtitle = shipment.subtitle[agent][method];
                }
                setup.option.method[agent].push(method_option);
              }
              this.Alphabetize(setup.option.method[agent], 'label');
            }
            this.Alphabetize(setup.option.agent, 'label');
          }
          const locale = String(this.$i18n.locale).split('_').slice(-1)[0];
          let country = this.country.options.find(option => option.code == locale) || 'GB';
          country = country || {id: 826, code: 'GB', label: this.CountryName('GB')};
          country = {id: 208, code: 'DK', label: this.CountryName('DK')}; // Force Denmark as default country option
          shipment.country.sender = country;
          shipment.country.recipient = country;
          shipment.shipping = {agent: '', method: ''};
          country = shipment.country;
          const country_shipping = this.CloneObject(this.country.shipping);
          const agent_options = this.CloneObject(this.shipping.option.agent);
          const sender_recipient = country.sender.code + '_' + country.recipient.code;
          const country_shipping_selected = country_shipping[sender_recipient] || {};
          const country_shipping_keys = Object.keys(country_shipping_selected);
          const shipping_keys = Object.keys(country_shipping);
          const shipping_sender_keys = shipping_keys.filter(key => key.split('_')[0] == country.sender.code);
          const recipient_country_options = shipping_sender_keys.map(key => this.country.options.find(option => option.code == key.split('_')[1])).filter(e => e);
          setup.option.agent = agent_options.filter(option => country_shipping_keys.includes(option.code));
          country.options.recipient = recipient_country_options || '';
          // Load country postal codes and city names
          for (let key in country) {
            if (['sender', 'recipient'].includes(key)) {
              country.select[key].loading = true;
              shipment.form.disabled = true;
              if (!await this.GetPostalCodeCity(country[key].code)) break;
              country.select[key].loading = false;
              shipment.form.disabled = false;
            }
          }
          this.ClearShipmentSetup();
          if (spinner) this.loading = false;
          return resolve(true);
        });
      },
      ClearShipmentSetup(preserve_shipping_method) {
        const shipment = this.modal.create.shipment;
        const language = shipment.language.options.find(option => option.code == 'da_DK');
        const address = this.address;
        this.modal.create.mode = 'manual';
        delete shipment.id;
        if (!preserve_shipping_method) {
          shipment.shipping.method = '';
        }
        shipment.template = null;
        shipment.setup.title = '';
        shipment.action = 'print';
        shipment.title = '';
        shipment.shop = {
          selected: '',
          options: []
        };
        shipment.availability = {
          selected: '',
          options: [],
          service: '',
          token: ''
        };
        shipment.country.select = {
          sender: {
            loading: false
          },
          recipient: {
            loading: false
          },
        };
        shipment.language.selected = language;
        shipment.email = {
          to: '',
          name: '',
          subject: '',
          template_args: {}
        };
        shipment.tab = {
          active: 0,
          page: []
        };
        shipment.form = {
          disabled: false,
          step: {
            back: false,
            next: false,
            last: true
          },
          data: {}
        };
        address.initialized = false;
        address.sender.selected = '';
        address.recipient.selected = '';
      },
      async GetLabelGeneratorTemplate(agent, method) {
        return await new Promise((resolve, reject) => {
          if (!agent || !method) return reject();
          BPA.api.GetLabelGeneratorTemplate([agent, method]).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async SetupShipmentTemplate(spinner) {
        if (spinner) this.loading = true;
        const shipment = this.modal.create.shipment;
        const settings = shipment.setup.settings;
        const agent = shipment.shipping.agent.code;
        const method = shipment.shipping.method.code;
        const options = this.shipping.option.method[agent];
        let parcel_shop_search = this.parcel_shop_search;
        let definition = settings[agent][method];
        if (!definition) return;
        definition = definition.definition;
        let fields = Object.values(definition);
        if (fields.some(field => /shop_finder/.test(field.widget))) {
          parcel_shop_search = await this.CanSearchParcelShops();
        }
        this.ClearShipmentSetup(true);
        shipment.tab.page = [];
        let option = {};
        for (let i = 0; i < options.length; i++) {
          if (options[i].code == method) {
            option = options[i];
            let shipping_option = options.find(o => o.code == option.code);
            if (!shipping_option.template) {
              shipping_option.template = await this.GetLabelGeneratorTemplate(agent, method);
            }
            option.template = shipping_option.template;
            break;
          }
        }
        if (!option.template || !option.template.page) {
          // Purge shipping method option if no template is available
          //shipment.setup.option.method[agent] = options.filter(o => o.code != option.code);
          //delete option.template;
          shipment.shipping.method = '';
          if (!shipment.setup.option.method[agent].length) {
            // Purge shipping agent option if no shipping method options available
            //let agents = shipment.setup.option.agent;
            //shipment.setup.option.agent = agents.filter(o => o.code != agent);
            shipment.shipping.agent = '';
          }
          this.$eventHub.$emit('ShowMessages', {
            message: 'Invalid label generator template',
            type: 'error',
            hide: 2000
          });
          return;
        }
        // PostNord services and addons
        if (agent == 'pdk' && !shipment.service[agent].options.length) {
          shipment.service[agent].options = await this.GetPostNordServiceOptions() || [];
        }
        const tabs = [];
        const template = this.CloneObject(option.template);
        const Key = (obj, str) => Object.keys(obj).find(key => key.includes(str));
        const Class = x => x = String(x.match(/('.*?')/)?.[0]).replace(/'/g, '');
        const Type = x => {
          switch (x) {
            case 'str': return 'text';
            case 'list': return 'select';
            case 'email': return 'email';
            case 'future_date': return 'date';
            case 'datetime': return 'datetime-local';
            case 'int': case 'float': return 'number';
            case 'tel': case 'tlf': case 'phone': return 'tel';
          }
        }
        const isArray = array => array && Array.isArray(array);
        const isObject = object => object && !isArray(object) && typeof object == 'object' && object instanceof Object;
        const serialize = async array => {
          let regex = new RegExp('@|type_', 'ig');
          for (let item of array) {
            if (isObject(item)) {
              for (let key in item) {
                if (/ref$/.test(key)) {
                  item.role = item[key].split('_')[0];
                }
                if (regex.test(key)) {
                  let new_key = key.replace(regex, '');
                  item[new_key] = item[key];
                  delete item[key];
                  key = new_key;
                }
                if (key == 'class') {
                  const date = new Date();
                  const form = shipment.form.data;
                  const def = definition[item.name];
                  const nullable = item[Key(item, 'nullable')];
                  item.class = Class(item.class);
                  item.type = Type(def.widget || item.class);
                  if (item.class == 'int') item.step = 1;
                  if (item.class == 'float') item.step = 0.01;
                  if (item.class == 'list') item.multiple = true;
                  if (/datetime/.test(item.ref)) {
                    item.type = 'datetime-local';
                    item.min = this.DateToISO(date).replace(' ', 'T').slice(0, -3);
                    if (!nullable) form[item.name] = item.min;
                  }
                  if (item.type == 'date') {
                    date.setHours(0, 0, 0, 0);
                    let min = def.type.default;
                    if (/tomorrow/i.test(min)) {
                      date.setDate(date.getDate() + 1);
                      item.min = Tool.DateString(date);
                    }
                    if (item.min) form[item.name] = item.min;
                  }
                }
                if (key == 'default') {
                  if (!isNaN(item[key])) {
                    shipment.form.data[item.name] = item[key];
                    // default country number will be overwritten
                  }
                }
                if (key == 'string') {
                  item.label = item[key];
                  delete item[key];
                }
                if (key == 'min_value') {
                  item.min = item[key];
                  delete item[key];
                }
                if (key == 'max_value') {
                  item.max = item[key];
                  delete item[key];
                }
                if (key == 'min_len') {
                  if (!item.min) {
                    item.minlength = item[key];
                  }
                  delete item[key];
                }
                if (['required', 'hidden'].includes(key)) {
                  item[key] = true;
                }
                if (key == 'regular_expression') {
                  item.pattern = item[key];
                  delete item[key];
                }
                if (key == 'widget') {
                  if (/weight|currency|country|shop|customer|service|addons|coverage|sort_code|address_type/.test(item.widget)) {
                    item.options = [];
                    //item.value = '';
                    item.type = 'select';
                    item.loading = false;
                  }
                  if (/weight/.test(item.widget)) {
                    let unit = item.widget.split('_').pop() || '';
                    item.options = shipment.weight[unit] || [];
                  }
                  if (/currency/.test(item.widget)) {
                    if (!shipment.currency) {
                      shipment.currency = await this.GetCurrencyOptions() || [];
                    }
                    item.options = this.Alphabetize(shipment.currency.map(option => {option.label = this.CurrencyName(option.code); return option}), 'label');
                    if (agent == 'instabox') {
                      item.options = item.options.filter(option => ['DKK', 'EUR', 'NOK', 'SEK', 'USD'].includes(option.code));
                    }
                    let country_currency = item.options.find(option => option.iso == shipment.country.sender.code);
                    if (country_currency) {
                      shipment.form.data[item.name] = country_currency.code;
                      item.value = country_currency;
                    }
                  }
                  if (/country/.test(item.widget)) {
                    shipment.form.data[item.name] = /number/.test(item.widget) ? 0 : '';
                    item.options = this.country.options;
                    item.disabled = true;
                  }
                  if (/shop/.test(item.widget)) {
                    item.options = shipment.shop.options;
                    item.value = shipment.shop.selected;
                    item.fill_width = true;
                  }
                  if (/bring/.test(item.widget)) {
                    let country_code = shipment.country.recipient.code;
                    if (!shipment.bring) {
                      shipment.bring = await this.GetBringCustomerNumbers() || {};
                    }
                    if (country_code in shipment.bring) {
                      item.options = shipment.bring[country_code];
                      shipment.form.data[item.name] = item.options[0].code;
                      item.value = item.options[0];
                    }
                  }
                  if (/burd/.test(item.widget)) {
                    if (!shipment.burd) {
                      shipment.burd = await this.GetBurdCoverage() || [];
                      //shipment.burd = await this.GetBurdAvailability() || [];
                    }
                    let country_postal_data = this.postal[shipment.country[item.role].code];
                    let postals_codes = Object.keys(country_postal_data);
                    for (let option of shipment.burd) {
                      if (postals_codes.includes(option.code)) {
                        option = option.code;
                        if (/city/.test(item.ref)) {
                          option = country_postal_data[option];
                        }
                        if (!item.options.includes(option)) {
                          item.options.push(option);
                        }
                      }
                    }
                    item.options.sort();
                    item.clearable = false;
                  }
                  if (/instabox/.test(item.widget)) {
                    shipment.availability.service = shipment.shipping.method.code.toUpperCase();
                    if (/availability_token/.test(item.widget)) {
                      item.value = shipment.availability.token;
                    }
                    if (/sort_code/.test(item.widget)) {
                      item.options = shipment.availability.options;
                      item.value = shipment.availability.selected;
                      item.fill_width = true;
                    }
                  }
                  if (/dhl/.test(item.widget)) {
                    item.options = [
                      {code: 'doorstep', label: 'Door step'},
                      {code: 'parcelshop', label: 'Parcel shop'},
                      {code: 'parcelstation', label: 'Parcel station'},
                      {code: 'postOffice', label: 'Post office'}
                    ];
                  }
                  if (/service|addons/.test(item.widget)) {
                    let country_code = shipment.country.recipient.code;
                    let services = shipment.service[agent].options || [];
                    let service_countries = shipment.service[agent].countries || {};
                    let options = [];
                    if (/postnord/.test(item.widget)) {
                      if (/service/.test(item.widget)) {
                        if (/pickup/.test(item.widget)) {
                          options = ['P19DK', 'P19DKBP', 'P19DKDPD'];
                        }
                        if (/private/.test(item.widget)) {
                          options = ['PDK17', 'PDK17BP', 'PDK17WORLD', 'PDK17DPD'];
                        }
                        options = options.filter(option => service_countries[option].includes(country_code));
                        item.options = services.filter(service => options.includes(service.code));
                        item.value = '';
                      }
                      if (/addons/.test(item.widget)) {
                        if (/return/.test(item.widget)) {
                          options = [['DK', 'SE', 'NO'].includes(country_code) ? 'P24DK' : 'P24DKDPD'];
                          item.options = services.find(service => options.includes(service.code))?.addons || [];
                        }
                        item.fill_width = true;
                      }
                    }
                  }
                  if (/customs_declaration/.test(item.widget)) {
                    item.list = {currency: '', item: {total: 0.00}, items: []};
                    item.fill_width = true;
                    item.type = 'list';
                    item.value = [];
                  }
                  if (/address_book/.test(item.widget)) {
                    let country_role = item.role;
                    let country_roles = ['sender', 'recipient'];
                    let return_label = /return/.test(shipment.shipping.method.code);
                    if (return_label) {
                      country_role = country_roles.find(role => role != country_role);
                    }
                    let item_country_role = country_role;
                    if (return_label && agent == 'dhl') {
                      item_country_role = country_roles.find(role => role != item_country_role);
                    }
                    let shipment_country = shipment.country[item_country_role];
                    let address_book_options = this.CloneObject(this.address.options);
                    address_book_options = address_book_options.filter(option => option.res_country_id == shipment_country.id);
                    if (address_book_options.length) {
                      item.options = address_book_options;
                      item.widget = 'address_book';
                    } else {
                      item.widget = 'address_book_empty';
                      delete item.options;
                    }
                  }
                  if (/phone_prefix/.test(item.widget)) {
                    let shipment_country = shipment.country[item.role];
                    item.options = this.CloneObject(this.country.phone.options);
                    item.selected = item.options.find(option => option.iso == shipment_country.code) || '';
                    item.type = 'tel';
                    item.value = '';
                  }
                  if (/phone_type/.test(item.widget)) {
                    item.options = [];
                    item.selected = '';
                    item.type = 'tel';
                    item.value = '';
                  }
                }
                if (key == 'help_text') {
                  item.title = item[key];
                  delete item[key];
                }
                if (isArray(item[key])) {
                  await serialize(item[key]);
                  break;
                }
              }
            } else if (isArray(item)) {
              await serialize(item);
              break;
            }
          }
        }
        if (!isArray(template.page.tab)) {
          const tab = this.CloneObject(template.page.tab);
          if (!isArray(tab.group)) {
            tab.group = [tab.group];
            const group = tab.group[0];
            if (group && !isArray(group.field)) {
              group.field = [group.field];
            }
          } else {
            for (let group of tab.group) {
              if (group && !isArray(group.field)) {
                group.field = [group.field];
              }
            }
          }
          tabs.push(tab);
        } else {
          for (let tab of template.page.tab) {
            if (!isArray(tab.group)) {
              tab.group = [tab.group];
            }
            for (let group of tab.group) {
              if (group && !isArray(group.field)) {
                group.field = [group.field];
              }
            }
            tabs.push(tab);
          }
        }
        await serialize(tabs);
        //console.log('tabs', this.CloneObject(tabs))
        const address_fields = ['address', 'postal', 'city'];
        const address_regex = new RegExp(address_fields.join('|'));
        const form = shipment.form.data;
        let finding_options = false;
        for (let i = 0; i < tabs.length; i++) {
          //tabs[i].clickable = i == 0;
          for (let group of tabs[i].group) {
            for (let field of (group || {}).field || []) {
              // Set country role
              let country_role = field.role;
              let country_roles = ['sender', 'recipient'];
              let return_label = /return/.test(shipment.shipping.method.code);
              /*
              let abroad_shipment = shipment.country.sender.code != shipment.country.recipient.code;
              if (return_label && abroad_shipment) {
                country_role = country_roles.find(role => role != country_role);
              }
              */
              if (return_label) {
                country_role = country_roles.find(role => role != country_role);
              }
              // All fields in current tab
              const fields = [].concat.apply([], tabs[i].group.map(item => item.field));
              // Safety precaution if field is missing vital properties
              for (let prop of ['name', 'ref', 'widget']) {
                if ('@' + prop in field) {
                  field[prop] = field['@' + prop];
                  delete field['@' + prop];
                }
              }
              // Set select clearability
              if (field.type == 'select') {
                if (!('clearable' in field)) {
                  field.clearable = !field.required && !field.disabled;
                }
                field.input = (option) => {
                  if (/shop_id/.test(field.ref) && !parcel_shop_search) {
                    let value = option.target.value;
                    field.value = value.replace(field.int ? /\d/g : /\D/g, '').trim();
                    form[field.name] = field.value;
                    return;
                  }
                  const list = Array.isArray(option);
                  const clearHiddenParcelFields = () => {
                    if (/shop/.test(field.widget)) {
                      for (let key in form) {
                        if (/parcel/.test(key)) {
                          delete form[key];
                        }
                      }
                    }
                  }
                  if (!option) {
                    field.value = '';
                    delete form[field.name];
                    clearHiddenParcelFields();
                    return;
                  }
                  field.value = option;
                  form[field.name] = list ? option.map(o => ({id: o.code})) : option.code || option;
                  if (!form[field.name]) {
                    delete form[field.name];
                    clearHiddenParcelFields();
                  }
                }
              }
              // Address book select at name field
              if (/^(sender_)?(recipient_)?(first_)?name$/.test(field.ref)) {
                /*if (!return_label)*/ this.RefreshAddressOptions(field.role, true);
              }
              // Phone type
              if (/phone_type/.test(field.widget)) {
                let phone_fields = fields.filter(field => /_phone/.test(field.ref));
                field.options = phone_fields.map(field => {
                  let label = field.ref.replace(/^(.*?)_(.*?)_phone$/, '$2') || '';
                  return {name: field.name, label: this.Capitalize(label)};
                });
                field.blur = (event) => {
                  let value = event.target.value || ''
                  for (let phone_field of phone_fields) {
                    if (phone_field == field && value) {
                      form[phone_field.name] = value;
                    } else {
                      delete form[phone_field.name];
                    }
                  }
                }
              }
              if (/address_type/.test(field.widget)) {
                // set "doorstep" as selected value
                let option = field.options.find(option => option.code == 'doorstep') || '';
                form[field.name] = (option || {}).code || '';
                field.value = option;
              }
              // Country select
              if (/country/.test(field.ref)) {
                let field_country_role = country_role;
                if (return_label && agent == 'dhl') {
                  field_country_role = country_roles.find(role => role != field_country_role);
                }
                const option = shipment.country[field_country_role];
                const property = /id/.test(field.ref) ? 'id' : 'code';
                field.options = shipment.country.options[field_country_role];
                form[field.name] = option[property];
                field.value = option;
                field.input = (option) => {
                  const options = this.country.options;
                  const name = field.name;
                  const value = option[property];
                  shipment.country[field_country_role] = options.find(option => option[property] == value);
                  field.value = option;
                  form[field.name] = value;
                  // Set sibling country select to same value
                  for (let tab of shipment.tab.page) {
                    for (let group of tab.group) {
                      for (field of (group || {}).field || []) {
                        if (/recipient/.test(field.ref) && field.name != name) {
                          form[field.name] = value;
                          field.value = option;
                        }
                      }
                    }
                  }
                }
              }
              //Currency select
              if (/currency/.test(field.ref)) {
                field.input = async (option) => {
                  let customs_declaration = fields.find(field => /customs_declaration/.test(field.widget));
                  if (customs_declaration) customs_declaration.list.currency = option ? option.code : '';
                  form[field.name] = option ? option.code : '';
                  field.value = option || '';
                  if (!form[field.name]) {
                    delete form[field.name];
                  }
                  // Force update field.value with v-if hack
                  // https://michaelnthiessen.com/force-re-render
                  this.render = false;
                  await this.$nextTick();
                  this.render = true;
                }
              }
              if (/freight/.test(field.ref)) {
                form[field.name] = Number(0).toFixed(2);
                field.value = Number(0).toFixed(2);
                field.min = 0;
              }
              // Service and addons
              if (/service/.test(field.widget)) {
                let service_addons_field = fields.find(field => /addons/.test(field.widget));
                field.input = (option) => {
                  service_addons_field.options = option ? option.addons : [];
                  service_addons_field.value = [];
                  form[service_addons_field.name] = [];
                  //delete form[service_addons_field.name];
                  // Select email-notification if possible
                  if (!['GL'].includes(shipment.country.recipient.code)) {
                    let email_notification = service_addons_field.options.find(option => option.code == 'NOTEMAIL');
                    if (email_notification) {
                      service_addons_field.value = [email_notification];
                      form[service_addons_field.name] = [{id: email_notification.code}];
                    }
                  }
                  if (/customs_declaration/.test(shipment.shipping.method.code)) {
                    //if (!['FO'].includes(shipment.country.recipient.code)) {
                      let economy_by_ship = service_addons_field.options.find(option => option.code == 'ECONOMY');
                      if (economy_by_ship) {
                        service_addons_field.value.push(economy_by_ship);
                        form[service_addons_field.name].push({id: economy_by_ship.code});
                      }
                    //}
                  }
                  form[field.name] = option ? option.code : '';
                  field.value = option || '';
                  if (!form[field.name]) {
                    delete form[field.name];
                  }
                }
                if (field.options.length == 1) {
                  field.value = field.options[0];
                  form[field.name] = field.value.code;
                  field.input(field.value);
                }
                if (field.value && field.value.code && service_addons_field && field.value.code == 'PDK17WORLD') {
                  service_addons_field.required = service_addons_field.options.some(option => option.code == 'ECONOMY');
                }
              }
              if (/customs_declaration/.test(field.widget)) {
                let currency_field = fields.find(field => /currency/.test(field.widget));
                if (currency_field) {
                  const currency_check = () => {
                    clearTimeout(currency_check.timer);
                    if (currency_field.value) {
                      let currency_code = typeof currency_field.value == 'string' ? currency_field.value : typeof currency_field.value == 'object' ? currency_field.value.code : '';
                      return field.list.currency = currency_code;
                    }
                    currency_check.timer = setTimeout(currency_check);
                  }
                  currency_check();
                }
              }
              // Shop finder
              if (field.widget == 'shop_finder') {
                delete field.min;
                delete field.step;
                if (parcel_shop_search) {
                  const shop_country = () => {
                    let option = shipment.country.recipient;
                    const options = this.country.options;
                    const country_field = fields.find(field => /country/.test(field.ref));
                    if (country_field) option = options.find(option => option[/id/.test(country_field.ref) ? 'id' : 'code'] == form[country_field.name]);
                    return option.code;
                  }
                  let previous_lookup = '';
                  let shop_finder_field = {};
                  for (let i = 0; i < tabs.length; i++) {
                    for (let group of tabs[i].group) {
                      for (let item of (group || {}).field || []) {
                        if (item.widget == 'shop_finder') {
                          shop_finder_field = item;
                          break;
                        }
                      }
                    }
                  }
                  const concat = {};
                  const reset_shop_options = () => {
                    shipment.shop.options = [];
                    shipment.shop.selected = '';
                    field.options = shipment.shop.options;
                    field.value = shipment.shop.selected;
                    for (let key in form) {
                      if (/pickup|parcel|shop/.test(key)) {
                        if (/id|name|address/.test(key)) {
                          delete form[key];
                        }
                      }
                    }
                    shop_finder_field.loading = false;
                    finding_options = false;
                    previous_lookup = '';
                  }
                  const address_concat = (event, index, item) => {
                    let element_refs = ['address', 'address2', 'postal_code', 'city'];
                    let address_elements = element_refs.map(ref => this.$refs[`${item.role}_${ref}`]).filter(e => e).flat();
                    address_elements.map((element, index) => concat[index + '_' + element.name] = form[element.name]);
                    concat[index + '_' + item.name] = form[event.target.name];
                    let concat_city_key = Object.keys(concat).find(key => /city/.test(key));
                    if (!concat_city_key) concat_city_key = 'city';
                    if (item.city) concat[concat_city_key] = item.city;
                    let address = Object.values(concat).join(' ').replace(/\s+/g, ' ').trim();
                    let city_value = this.postal[shipment.country[item.role].code][address];
                    let field_name = concat_city_key.replace(/^\d_/, '');
                    if (city_value) {
                      concat[concat_city_key] = city_value;
                      if (event.target.name == field_name) {
                        event.target.value = city_value;
                        form[field_name] = city_value;
                      }
                    }
                    if (!Object.keys(form).some(key => /postal|zip|city/.test(key))) {
                      concat[concat_city_key] = '';
                    }
                    address = Object.values(concat).join(' ').replace(/\s+/g, ' ').trim();
                    if (address == previous_lookup || finding_options) return;
                    if (!address) return reset_shop_options();
                    shop_finder_field.loading = true;
                    previous_lookup = address;
                    finding_options = true;
                    BPA.api.GetParcelShops({
                      agent_code: agent,
                      country_code: shop_country(),
                      address: address.trim()
                    }).then(response => {
                      return BPA.api.response({response, return: 'text'});
                    }).then(response => {
                      finding_options = false;
                      shop_finder_field.loading = false;
                      if (!response.ok || !response.result) return;
                      if (!Tool.IsJsonString(response.result)) {
                        return reset_shop_options();
                      }
                      const data = JSON.parse(response.result);
                      if (!data.items || !data.items.length) {
                        return reset_shop_options();
                      }
                      const options = [];
                      for (let item of data.items) {
                        let option = {data: data};
                        let meters = item.distance_m || 0;
                        let unit = meters >= 1000 ? 'km' : 'm';
                        let kilometers = meters / 1000;
                        kilometers = this.NumberFormat(Math.round(kilometers * 100) / 100);
                        let distance = (unit == 'km' ? kilometers : meters) + ' ' + unit;
                        const strip_parcel_shop_id = (address) =>  String(address).replace(/\n?\s?pakkeshop:?.*$/i, '').replace(/\s+/g, ' ').trim();
                        const address = (id) => strip_parcel_shop_id(item.address) + (id ? ' Pakkeshop: ' + id : '') + ', ' + item.zipcode + ' ' + item.city;
                        option.code = item.parcel_shop_id;
                        option.name = item.company_name;
                        option.address = address(item.parcel_shop_id);
                        option.label = '[' + item.parcel_shop_id + '] ' + option.name + ' - ' + address() + ' (' + distance + ')';
                        options.push(option);
                      }
                      if (options.length > 20) {
                        options.length = 20;
                      }
                      shipment.shop.options = options;
                      shipment.shop.selected = options[0] || '';
                      field.options = shipment.shop.options;
                      field.value = shipment.shop.selected;
                      form[field.name] = field.value.code;
                      field.input(field.value);
                    }).catch(e => e);
                  }
                  field.input = (option) => {
                    let shop_fields = fields.filter(field => field.ref.includes('shop'));
                    shop_fields.forEach(shop_field => {
                      if (shop_field.ref.includes('name')) {
                        if (option) form[shop_field.name] = option.name;
                        else delete form[shop_field.name];
                      }
                      if (shop_field.ref.includes('address')) {
                        if (option) form[shop_field.name] = option.address;
                        else delete form[shop_field.name];
                      }
                    });
                    form[field.name] = option ? option.code : '';
                    field.value = option || '';
                    if (!form[field.name]) {
                      delete form[field.name];
                    }
                  }
                  let address_fields = fields.filter(field => address_regex.test(field.ref));
                  address_fields.forEach((field, index) => {
                    concat[index + '_' + field.name] = '';
                    field.blur = (event) => address_concat(event, index, field);
                    field.type = 'search';
                  });
                } else { // If no Google Maps API key
                  console.log('No Google Maps API key', this.CloneObject(field))
                  for (let prop of ['widget', 'options', 'clearable']) {
                    delete field[prop];
                  }
                  field.label = 'Parcel shop ID';
                  field.fill_width = false;
                  field.type = 'text';
                }
              }
              // Instabox delivery options
              if (/instabox_sort_code/.test(field.widget)) {
                delete field.min;
                delete field.step;
                let previous_lookup = '';
                let delivery_option_field = field || {};
                const request = {
                  country_code: shipment.country[field.role].code,
                  service_type: shipment.availability.service,
                  zipcode: ''
                };
                const reset_delivery_options = () => {
                  shipment.availability.options = [];
                  shipment.availability.selected = '';
                  shipment.availability.token = '';
                  field.options = shipment.availability.options;
                  field.value = shipment.availability.selected;
                  for (let key in form) {
                    if (/token|sort/.test(key)) {
                      delete form[key];
                    }
                  }
                  delivery_option_field.loading = false;
                  finding_options = false;
                  previous_lookup = '';
                }
                const lookup_delivery_options = async (event) => {
                  let postal_code = event.target.value;
                  form[event.target.name] = postal_code;
                  if (postal_code == previous_lookup || finding_options) return;
                  if (!postal_code) return reset_delivery_options();
                  delivery_option_field.loading = true;
                  previous_lookup = postal_code;
                  request.zipcode = postal_code;
                  finding_options = true;
                  let data = await this.GetInstaboxAvailability(request);
                  delivery_option_field.loading = false;
                  finding_options = false;
                  if (!data) return reset_delivery_options();
                  shipment.availability.token = data.availability_token;
                  let availability = data.availability[request.service_type];
                  let dispatch_options = availability.dispatch_options || [];
                  let delivery_options = [];
                  if (dispatch_options.length) {
                    dispatch_options = dispatch_options[0];
                    delivery_options = dispatch_options.delivery_options || [];
                    //delivery_options.sort((a, b) => new Date(a.eta.datetime_local) - new Date(b.eta.datetime_local));
                    const options = [];
                    for (let item of delivery_options) {
                      let option = {code: item.sort_code};
                      let directions = item.directions;
                      let address = item.address;
                      const locker = {
                        get address() {
                          return address ? ` ${address.street}, ${address.zip} ${address.city}` : '';
                        },
                        get location() {
                          let location = '';
                          if (this.address || directions) {
                            location = ` -${this.address}`;
                            if (directions) {
                              let directions_short = String(directions.short || directions.long || '').replace(/\.$/, '');
                              if (this.address && directions_short) {
                                directions_short = `(${directions_short})`;
                              }
                              location += ` ${directions_short}`;
                              option.title = directions.long;
                            }
                          }
                          return location.replace(',,', ',');
                        }
                      };
                      if (/home/i.test(request.service_type)) {
                        item.description = String(item.description).replace(/^(.*)\s(\d{1,2})\s(.*)$/, '$1 $2. $3');
                        item.description = item.description.replace(/ kl /, ' kl. ');
                      }
                      option.label = `[${item.sort_code}] ${item.description}${locker.location}`;
                      options.push(option);
                    }
                    if (options.length > 20) {
                      options.length = 20;
                    }
                    shipment.availability.options = options;
                    shipment.availability.selected = options[0] || '';
                    field.options = shipment.availability.options;
                    field.value = shipment.availability.selected;
                    form[field.name] = field.value.code;
                    field.input(field.value);
                  }
                }
                field.input = (option) => {
                  form[field.name] = option ? option.code : '';
                  field.value = option || '';
                  if (!form[field.name]) {
                    delete form[field.name];
                  }
                  let availability_token_field = fields.find(field => /availability_token/.test(field.widget));
                  if (availability_token_field) {
                    if (form[field.name]) {
                      form[availability_token_field.name] = shipment.availability.token;
                    } else {
                      delete form[availability_token_field.name];
                    }
                  }
                }
                let postal_code_field = fields.find(field => /availability_postal/.test(field.widget));
                if (postal_code_field) {
                  postal_code_field.blur = lookup_delivery_options;
                  postal_code_field.type = 'search';
                }
              }
              if (/postal/.test(field.ref)) {
                if (field.type == 'text') {
                  field.input = (event) => {
                    let value = event.target.value;
                    let city_field = fields.find(field => /city/.test(field.ref));
                    if (city_field && country_role) {
                      let country_option = shipment.country[country_role];
                      let city_value = this.postal[country_option.code][value];
                      form[city_field.name] = city_value || '';
                      city_field.value = city_value || '';
                      field.city = city_value || '';
                      //this.$refs[city_field.ref][0].dispatchEvent(new Event('blur'));
                    }
                    form[field.name] = value || '';
                  }
                }
                if (/burd_coverage/.test(field.widget)) {
                  field.input = async (option) => {
                    let city_field = fields.find(field => /burd_coverage_city/.test(field.widget));
                    if (city_field && option) {
                      let country_option = shipment.country[city_field.role];
                      city_field.value = this.postal[country_option.code][option] || '';
                      form[city_field.name] = city_field.value;
                      if (!form[city_field.name]) {
                        delete form[city_field.name];
                      }
                    }
                    field.value = option || '';
                    form[field.name] = field.value;
                    if (!form[field.name]) {
                      delete form[field.name];
                    }
                    // Force update field.value with v-if hack
                    // https://michaelnthiessen.com/force-re-render
                    this.render = false;
                    await this.$nextTick();
                    this.render = true;
                  }
                }
              }
              if (/city/.test(field.ref)) {
                if (/burd_coverage/.test(field.widget)) {
                  field.input = async (option) => {
                    let postal_field = fields.find(field => /burd_coverage_postal/.test(field.widget));
                    if (postal_field && option) {
                      let country_option = shipment.country[postal_field.role];
                      let postal_options = this.postal[country_option.code];
                      postal_options = Object.keys(postal_options).filter(code => postal_options[code] == option);
                      if (!postal_options.includes(postal_field.value)) {
                        delete form[postal_field.name];
                        postal_field.value = '';
                      }
                      if (postal_options.length == 1) {
                        postal_field.value = postal_options[0];
                        form[postal_field.name] = postal_field.value;
                      }
                    }
                    field.value = option || '';
                    form[field.name] = field.value;
                    if (!form[field.name]) {
                      delete form[field.name];
                    }
                    // Force update field.value with v-if hack
                    // https://michaelnthiessen.com/force-re-render
                    this.render = false;
                    await this.$nextTick();
                    this.render = true;
                  }
                }
              }
              if (address_regex.test(field.ref)) {
                field.role = field.ref.split('_')[0];
              }
            }
          }
        }
        shipment.tab.page = tabs;
        shipment.template = template;
        let shipment_title = shipment.shipping.method.label;
        if (!new RegExp(shipment.shipping.agent.label, 'i').test(shipment_title)) {
          shipment_title = shipment.shipping.agent.label + ' ' + this.TitleCase(shipment_title);
        }
        shipment.setup.title = `:&nbsp; <span class="agent-icon ${shipment.shipping.agent.code}" title="${shipment.shipping.agent.label}"></span>${shipment_title}`;
        this.$nextTick().then(() => {
          // Swap sender and recipient if return label
          let sender_tab_text = 'Sender';
          let recipient_tab_text = 'Recipient';
          if (/return/i.test(method)) {
            if (tabs.length == 3) {
              tabs[2].title = sender_tab_text;
              tabs[1].title = recipient_tab_text;
              [tabs[1], tabs[2]] = [tabs[2], tabs[1]];
            }
          }
          if (spinner) {
            this.loading = false;
          }
          this.CreateShipmentStep();
          this.AutoFocusFirstEmptyRequiredField();
        });
      },
      AutoFocusFirstEmptyRequiredField() {
        this.$nextTick().then(() => {
          const shipment = this.modal.create.shipment;
          const form = shipment.form.data;
          const active_tab = shipment.tab.page[shipment.tab.active];
          if (!active_tab) return;
          const tab_fields = active_tab.group.map(group => ((group || {}).field || []).map(field => field)).flat();
          for (let field of tab_fields) {
            if (field.required && !form[field.name]) {
              let field_element = this.$refs[field.ref];
              if (Array.isArray(field_element)) {
                field_element = field_element[0];
              }
              if (field_element) {
                if (field_element.$vnode) field_element = field_element.$vnode.elm;
                let select_element = field_element.querySelector('input[type=search]');
                if (select_element) field_element = select_element;
                field_element.focus();
              }
              break;
            }
          }
        });
      },
      CreateShipmentStep(direction) {
        const tab = this.modal.create.shipment.tab;
        const step = this.modal.create.shipment.form.step;
        const index = {active: tab.active};
        index.back = index.active - 1;
        index.next = index.active + 1;
        if (direction == 'back') {
          tab.active = index.back;
          index.back--;
          index.next--;
        }
        if (direction == 'next') {
          tab.active = index.next;
          index.back++;
          index.next++;
          this.AutoFocusFirstEmptyRequiredField();
        }
        step.back = index.back >= 0;
        step.next = index.next < tab.page.length;
        step.last = index.next >= tab.page.length;
      },
      async ValidateCreateShipmentStep(callback) {
        const shipment = this.modal.create.shipment;
        const form_data = shipment.form.data;
        const active_index = shipment.tab.active;
        const active_tab = shipment.tab.page[active_index];
        const button_id = 'tab-page-form-' + active_index;
        const submit = document.getElementById(button_id);
        const form = submit.closest('form');
        const fields = Array.from(form.elements);
        const pattern = {
          email: new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)
        }
        console.log(this.CloneObject(form_data))
        for (let field of fields) {
          if (field.type == 'email') {
            const form_value = form_data[field.name];
            if ((field.required || field.value) && field.checkValidity() && !pattern.email.test(form_value)) {
              const language = navigator.language;
              const message = {
                da: 'Ugyldig mailadresse', 
                de: 'Ungültige E-Mail Adresse', 
                en: 'Invalid email address', 
                no: 'Ugyldig e-postadresse', 
                sv: 'Ogiltig e-postadress'
              };
              const error = message[language || 'en'] || message.en;
              field.setCustomValidity(error);
              submit.click();
              field.oninput = () => {
                clearTimeout(field.timeout);
                field.setCustomValidity('');
              }
              clearTimeout(field.timeout);
              return field.timeout = setTimeout(() => {
                field.setCustomValidity('');
              }, 5000);
            }
          }
        }
        if (fields.some(field => !field.checkValidity())) {
          return submit.click();
        }
        // Find list field and check validity of each input element
        const list_field = active_tab?.group.map(group => ((group || {}).field || []).find(field => field.list)).filter(e => e)[0];
        if (list_field && list_field.required) {
          if (!list_field.list.items.length && !await this.AddItem(list_field)) return;
          if (!await this.ValidateAddedItems(list_field)) return;
        }
        callback && callback();
      },
      async OpenCreateShipmentFormModal(original_shipment, is_return, args) {
        this.$eventHub.$emit('CloseModal', 'view.shipment');
        if (!original_shipment) return;
        this.loading = true;
        const country_shipping = this.CloneObject(this.country.shipping);
        const shipping_option = this.CloneObject(this.shipping.option);     
        if (original_shipment.hasOwnProperty('agent_code') && typeof original_shipment.agent_code == 'string') {
          original_shipment.agent = shipping_option.agent.find(option => option.code == original_shipment.agent_code);
        }
        if (original_shipment.hasOwnProperty('method') && typeof original_shipment.method == 'string') {
          original_shipment.method = shipping_option.method[original_shipment.agent.code].find(option => option.code == original_shipment.method);
        }
        const original = {
          agent: original_shipment.agent.code, 
          method: original_shipment.method.code, 
          shipment: original_shipment, 
          field: {name: {}, ref: {}}
        };
        original.template = await this.GetLabelGeneratorTemplate(original.agent, original.method);
        original.template = original.template ? original.template.page : [];
        if (!args) args = await this.GetLabelArgs(original.shipment.id);
        if (!await this.InitShipmentSetup()) return this.loading = false;
        const shipment = this.modal.create.shipment;
        const data = args.args.args;
        const setup = shipment.setup;
        const country = shipment.country;
        for (let tab of original.template.tab) {
          if (!Array.isArray(tab.group)) {
            tab.group = [tab.group];
          }
          for (let group of tab.group) {
            if (group && !Array.isArray(group.field)) {
              group.field = [group.field];
            }
            for (let field of (group || {}).field || []) {
              original.field.name[field['@name'].replace('@', '')] = field['@ref'];
              original.field.ref[field['@ref'].replace('@', '')] = field['@name'];
            }
          }
        }
        let shipping_agent = setup.option.agent.find(option => option.code == args.agent_code);
        let shipping_method = shipping_option.method[args.agent_code].find(option => is_return ? /return/.test(option.code) : option.code == args.method);
        shipping_method.subtitle = shipment.subtitle[shipping_method.agent][shipping_method.code];
        shipment.shipping.agent = shipping_agent;
        shipment.shipping.method = shipping_method;

        let sender_country = Object.keys(original.shipment).map(key => /sender/.test(key) && /country/.test(key) && original.shipment[key]).filter(e => e)[0] || '';
        if (sender_country instanceof Object) {
          sender_country = country.options.sender.find(option => option.id == sender_country.id);
        }
        if (!isNaN(sender_country)) {
          sender_country = country.options.sender.find(option => option.id == sender_country);
        }
        if (!sender_country) {
          sender_country = this.CloneObject(original.shipment.country);
          sender_country.label = sender_country.name;
          delete sender_country.name;
        }
        let recipient_country = original.shipment.country || {};
        if (!Object.keys(recipient_country).length) {
          recipient_country = Object.keys(original.shipment).map(key => /customer/.test(key) && /country/.test(key) && original.shipment[key]).filter(e => e)[0] || '';
        }
        if (sender_country instanceof Object) {
          recipient_country = country.options.recipient.find(option => option.id == recipient_country.id);
        }

        country.sender = sender_country;
        country.recipient = recipient_country;

        if (is_return) {
          country.sender = recipient_country;
          country.recipient = sender_country;
        }

        const sender_recipient = country.sender.code + '_' + country.recipient.code;
        const country_shipping_selected = country_shipping[sender_recipient] || {};
        const country_shipping_keys = Object.keys(country_shipping_selected);
        setup.option.agent = shipping_option.agent.filter(option => country_shipping_keys.includes(option.code));

        const shipping_methods = {};
        for (let agent in shipping_option.method) {
          for (let shipping in country_shipping[sender_recipient]) {
            if (shipping == agent) {
              shipping_methods[agent] = [];
              for (let method of shipping_option.method[agent]) {
                if (country_shipping[sender_recipient][shipping].includes(method.code)) {
                  method.subtitle = shipment.subtitle[agent][method.code];
                  shipping_methods[agent].push(method);
                }
              }
            }
          }
        }
        setup.option.method = shipping_methods;
        for (let key in country) {
          if (['sender', 'recipient'].includes(key)) {
            country.select[key].loading = true;
            shipment.form.disabled = true;
            if (!await this.GetPostalCodeCity(country[key].code)) break;
            country.select[key].loading = false;
            shipment.form.disabled = false;
          }
        }

        await this.SetupShipmentTemplate();

        this.modal.create.mode = 'recreate';

        const form = shipment.form.data;
        const tabs = shipment.tab.page;
        const shop_finder = {
          address_field: null,
          field: null
        };
        for (let tab of tabs) {
          for (let group of tab.group) {
            for (let field of (group || {}).field || []) {
              const fields = [].concat.apply([], tab.group.map(item => item.field));
              let data_key = original.field.ref[field.ref];
              let data_value = data[data_key];
              if (!data_value) {
                let role = field.ref.split('_')[0];
                if (new RegExp(`^(${role}_)?name$`).test(field.ref)) {
                  let name_refs = Object.keys(original.field.ref).filter(key => key.includes(role) && key.includes('name') && !key.includes('shop'));
                  data_value = name_refs.map(name_ref => data[original.field.ref[name_ref]]).filter(e => e).join(' ').trim();
                }
              }
              field.value = data_value;
              if (field.type == 'select') {
                if (field.options.length) {
                  let field_value = '';
                  field.value = field.options.find(option => {
                    for (let key in option) {
                      if (option[key] == field.value) {
                        field_value = option[key];
                        return option;
                      }
                    }
                  });
                  form[field.name] = field_value;
                } else {
                  if (field.widget == 'shop_finder') {
                    shop_finder.address_field = fields.find(field => /address/.test(field.ref));
                    shop_finder.code = this.CloneObject(field.value);
                    shop_finder.field = field;
                    delete form[field.name];
                    field.value = '';
                  } else {
                    form[field.name] = field.value;
                  }
                }
              } else {
                form[field.name] = field.value;
              }
              if (/date/.test(field.widget)) {
                field.value = field.min || '';
                form[field.name] = field.value;
                if (/item/.test(field.widget)) {
                  delete form[field.name];
                  field.value = '';
                }
              }
              if (/freight/.test(field.ref)) {
                field.value = String(parseFloat(form[field.name] || '0').toFixed(2));
              }
              if (/addons/.test(field.widget)) {
                let addons = data_value.map(addon => addon.id);
                field.value = field.options.filter(option => addons.includes(option.code));
                form[field.name] = addons.map(addon => ({id: addon}));
              }
              if (/customs_declaration/.test(field.widget)) {
                let currency_field = fields.find(field => /currency/.test(field.widget));
                if (currency_field) {
                  const currency_check = () => {
                    clearTimeout(currency_check.timer);
                    if (currency_field.options.length) {
                      currency_field.value = currency_field.options.find(option => option.code == data.currency_code);
                      console.log(data, data.currency_code, currency_field.options, currency_field.value)
                      return field.list.currency = currency_field.value.code;
                    }
                    currency_check.timer = setTimeout(currency_check);
                  }
                  currency_check();
                }
                field.list.items = form[field.name].map(item => ({
                  quantity: item.copies,
                  country: item.sourceCountryCode,
                  content: item.contents,
                  tariff: item.statNo,
                  weight: item.netWeight,
                  value: item.value,
                  total: item.copies * item.value
                }));
              }
            }
          }
        }
        // Check all form step input fields
        // Set focus to field if required but no value
        let missing_required = null;
        let active_tab_index = tabs.length - 1;
        for (let i = 0; i < tabs.length; i++) {
          const tab = tabs[i];
          for (let group of tab.group) {
            for (let field of (group || {}).field || []) {
              const fields = [].concat.apply([], tab.group.map(item => item.field));
              if (!field.value && field.required) {
                let resolved_missing = false;
                if (/postal|city/.test(field.ref)) {
                  if (!this.address.options.length) {
                    this.address.options = await this.GetAddressOptions();
                  }
                  let address_key = '';
                  switch (true) {
                    case /postal/.test(field.ref): address_key = 'zipcode'; break;
                    case /city/.test(field.ref): address_key = 'city';
                  }
                  let address_field = fields.find(field => /address/.test(field.ref)) || {};
                  let address_option = this.address.options.find(option => option.address == address_field.value) || {};
                  if (address_option[address_key]) {
                    field.value = address_option[address_key];
                    form[field.name] = field.value;
                    resolved_missing = true;
                  }
                }
                if (!resolved_missing) {
                  missing_required = field;
                  active_tab_index = i;
                  break;
                }
              }
            }
            //if (missing_required) break;
          }
          //f (missing_required) break;
        }
        
        console.log(this.CloneObject(form))
        shipment.tab.active = active_tab_index;
        this.loading = false;
        this.CreateShipmentStep();
        shipment.id = original_shipment.id;
        this.$nextTick().then(async () => {
          console.log('missing_required', missing_required)
          if (missing_required) {
            let missing_required_ref = this.$refs[missing_required.ref][0];
            if (!shop_finder.field || shop_finder.field.ref != missing_required_ref) {
              if (missing_required_ref.$vnode) {
                missing_required_ref = missing_required_ref.$vnode.elm;
              }
              missing_required_ref.focus();
            }
          }
          console.log('shop_finder.field', shop_finder.field)
          if (shop_finder.field) {
            this.$refs[shop_finder.address_field.ref][0].dispatchEvent(new Event('blur'));
            shop_finder.options = await new Promise(resolve => {
              const check_shop_options = () => {
                clearTimeout(check_shop_options.timeout);
                if (shop_finder.field.options.length) {
                  return resolve(shop_finder.field.options);
                }
                check_shop_options.timeout = setTimeout(check_shop_options);
              }
              check_shop_options();
            });
            shop_finder.field.value = shop_finder.options.find(option => option.code == shop_finder.code);
            if (!shop_finder.field.value) {
              delete form.parcelshop_name;
              delete form.parcelshop_address;
              delete form[shop_finder.field.name];
            }
          }
          if (active_tab_index == tabs.length - 1) {
            this.$refs.create_shipment_submit_button.click();
          }
        });
        this.Modal('create.shipment').open = true;
      },
      async SubmitCreateShipmentForm(action) {
        console.log('this.modal.create.shipment', this.CloneObject(this.modal.create.shipment))
        const shipment = this.modal.create.shipment;
        const form_data = this.CloneObject(shipment.form.data);
        const form_fields = Object.keys(form_data);
        const FindKeyValue = (regex = new RegExp(), key) => {
          return form_fields.map(field => regex.test(field) && (key ? field : form_data[field])).filter(e => e);
        }
        const request = {
          agent_code: shipment.shipping.agent.code,
          shipping_method: shipment.shipping.method.code,
          operation_code: action || 'print'
        }
        for (let field in form_data) {
          let value = form_data[field];
          if (/datetime/.test(field) && value) {
            let time = value.split('T')[1];
            if (time.split(':').length < 3) {
              value = value + ':00';
            }
            value = this.DateToUTC(value);
          }
          form_data[field] = value;
        }
        let create_mode_reference = '';
        let reference = FindKeyValue(/^ref/);
        let create_mode = this.modal.create.mode;
        let is_return = /return/.test(shipment.shipping.method.code);
        switch (create_mode) {
          case 'recreate': create_mode_reference = '{{Created from}}: ' + (shipment.id || reference || ''); break;
          default: create_mode_reference = '{{Manual}}';
        }
        shipment.action = request.operation_code;
        create_mode_reference += (is_return ? ' ({{Return}})' : '');
        if (Array.isArray(reference)) reference = reference.join(' ');
        let title = String(shipment.setup.title);
        let div = document.createElement('div');
        div.innerHTML = title;
        title = div.textContent;
        title = title.replace(/\s+/g, ' ').trim();
        shipment.title = title.replace(':', `[${is_return ? 'RETURN' : 'SHIPPING'} LABEL]`) + (reference ? ' (' + reference + ')' : '');
        this.popup.action = shipment.action == 'email' ? 'send' : shipment.action;
        if (shipment.action == 'print'/* && !this.printer.options.length*/) await this.RefreshPrinterOptions();
        if (shipment.action == 'email') {
          let country_regex = new RegExp(String(shipment.country.recipient.code), 'i');
          let language_selected = shipment.language.options.find(option => country_regex.test(option.code)) || {};
          let name_fields = FindKeyValue(/^(destination_)?(customer_)?(contact_)?(full_)?(last)?name$/, true);
          let name_field = name_fields.slice(-1)[0] || '';
          let email_name = form_data[name_field] || '';
          if (!language_selected.code) {
            language_selected = shipment.language.options.find(option => option.code == 'en_GB');
          }
          if (name_fields.length == 2 && /last/.test(name_field)) {
            email_name = form_data[name_fields[0]] + ' ' + email_name;
          }
          shipment.language.selected = language_selected;
          shipment.email.name = email_name;
          shipment.email.to = FindKeyValue(/^(destination_)?(customer_)?(contact_)?email$/).slice(-1)[0];
          shipment.email.subject = title.replace(':', 'Shipping label:') + (reference ? ' - ' + reference : '');
        }
        const confirmed = await new Promise(resolve => {
          this.popup.show = true;
          let selected_printer = this.printer.selected;
          this.popup.button.cancel.click = (event) => {
            if (shipment.action == 'print') {
              this.printer.selected = selected_printer;
            }
            if (event) event.preventDefault();
            resolve(false);
            return false;
          }
          this.popup.button.confirm.click = () => {
            this.popup.show = false;
            resolve(true);
          }
        });
        if (!confirmed) return;
        const fields = {};
        const tabs = this.CloneObject(shipment.tab.page);
        const setValueType = (item, value) => {
          if (!item) return;
          if (!value) {
            if (item.nullable) {
              return null;
            }
            if (item.class == 'list') {
              value = [];
            }
          }
          switch (item.class) {
            case 'int':
            case 'float':
              if (item.class == 'float') {
                value = String(parseFloat(value || '0').toFixed(2));
              } else {
                value = Number(value || 0);
              }
              break;
            case 'str':
              value = String(value || '');
          }
          if (item.pattern) {
            let regex = new RegExp(item.pattern);
            let match = value.match(regex);
            if (match) value = match[0];
          }
          return value;
        }
        for (let tab of tabs) {
          for (let group of tab.group) {
            for (let field of (group || {}).field || []) {
              fields[field.name] = field;
            }
          }
        }
        for (let field of Object.keys(fields)) {
          let item = fields[field];
          let value = form_data[field];
          value = setValueType(item, value);
          form_data[field] = value;
        }
        request.body = {title: shipment.title || '', args: form_data, ref: create_mode_reference};
        if (shipment.action == 'print') {
          request.body.printer_id = Number(this.printer.selected.id);
        }
        if (shipment.action == 'email') {
          let language_code = shipment.language.selected.code;
          const template_args = shipment.email.template_args;
          if (language_code == 'no_NO') language_code = 'nb_NO';
          template_args.email_name = shipment.email.name;
          //template_args.shipment_id = shipment.increment_id;
          //template_args.company_email = this.company.email;
          template_args.company_name = BPA.util.GetMainCompany().name;
          request.body.language = language_code;
          request.body.email = shipment.email;
        }
        //makes sure gross weight is float, for DHL
        if(request.body.args.gross_weight_kg && typeof request.body.args.gross_weight_kg == 'string' && request.agent_code == 'dhl')
        {
          request.body.args.gross_weight_kg = parseFloat(request.body.args.gross_weight_kg);
        }
        this.CreateCustomLabel(request);
      },
      async CreateCustomLabel(request = {}) {
        console.log(this.CloneObject(request))
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          this.loading = true;
          BPA.api.CreateCustomLabel(request).then(response => {
            return BPA.api.response({response, 
              return: () => {
                resolve(response);
                this.QueryLabels();
                this.$eventHub.$emit('CloseModal', 'create.shipment');
                this.$eventHub.$emit('ShowMessages', {
                  message: 'Label successfully created',
                  type: 'success',
                  hide: 2000
                });
                return response.arrayBuffer();
              },
              error: (message) => {
                reject(message);
                message = {text: message || ''};
                message.parts = message.text.split(' - ');
                if (message.parts.length == 2) {
                  if (request.agent_code == 'dhl') {
                    message = message.text;
                  }
                  if (request.agent_code == 'pdk') {
                    message.external = {
                      code: message.parts[1].match(/\[\d+\]/), 
                      errors: message.parts[1].match(/\[\D+\]/), 
                      message: []
                    };
                    console.log(message.external)
                    if (message.external.errors) {
                      for (let error of JSON.parse(message.external.errors)) {
                        message.external.message.push({
                          field: error.messageCode || error.field,
                          message: error.message
                        });
                      }
                    }
                    message.external.message = JSON.stringify(message.external.message) || '';
                    message = `${message.parts[0]} - ${message.external.code || ''} ${message.external.message}`;
                  }
                }
                this.$eventHub.$emit('ShowMessages', {
                  message: message,
                  type: 'error',
                  close: true
                });
              }
            }).then(response => {
              this.loading = false;
              if (!response.ok || !response.result) return reject();
              let arrayBuffer = response.result || {};
              if (request.operation_code == 'download') {
                let blob = new Blob([arrayBuffer], {type: 'application/pdf'});
                BPA.api.download({blob: blob, name: request.body.title, new_tab: BPA.browser == 'firefox'});
              }
            });
          }).catch(reject);
        }).catch(e => e);
      },
      async PrintShippingLabel(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!request.shipment_id || !request.printer_id) return reject();
          this.loading = true;
          BPA.api.PrintCustomLabel(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async FilterShippingMethods(agent_select_focused) {
        const shipment = this.modal.create.shipment;
        const setup = shipment.setup;
        //const settings = setup.settings;
        const country = shipment.country;
        const shipping = shipment.shipping;
        const country_shipping = this.CloneObject(this.country.shipping);
        const shipping_option = this.CloneObject(this.shipping.option);
        const sender_recipient = country.sender.code + '_' + country.recipient.code;
        const shipping_methods = {};
        for (let agent in shipping_option.method) {
          for (let shipping in country_shipping[sender_recipient]) {
            if (shipping == agent) {
              shipping_methods[agent] = [];
              for (let method of shipping_option.method[agent]) {
                /*
                let object = settings[agent][method.code];
                let definition = object.definition;
                let fields = Object.values(definition);
                let shop_finder = fields.find(field => /shop_finder/.test(field.widget));
                let parcel_shop_search = !!shop_finder && await this.CanSearchParcelShops();
                let add_method_option = shop_finder ? parcel_shop_search : true;
                */
                if (country_shipping[sender_recipient][shipping].includes(method.code)) {
                  /*if (add_method_option)*/ shipping_methods[agent].push(method);
                }
              }
              if (!shipping_methods[agent].length) {
                setup.option.agent = setup.option.agent.filter(option => option.code != agent);
              }
            }
          }
        }
        setup.option.method = shipping_methods;
        const agent = shipping.agent;
        const methods = setup.option.method;
        const method = shipping.method || {};
        const method_options = (methods[agent.code] || []).map(o => {o.subtitle = shipment.subtitle[o.agent][o.code]; return o;});
        const focus_select = (select) => {
          select = this.$refs[`shipping_${select}`];
          if (select) setTimeout(() => {select.$refs.search.focus()}, 100);
        }
        if (method_options.length == 1) {
          const method_option = method_options[0];
          if (!agent) {
            if (method) {
              if (method.code != method_option.code) {
                shipping.method = method_option;
              }
            } else {
              shipping.method = method_option;
            }
            this.ClearShipmentSetup();
          } else {
            if (method) {
              if (method.agent != agent.code) {
                shipping.method = '';
                this.ClearShipmentSetup();
              }
            }
            shipping.method = method_option;
          }
          if (method.code != method_option.code || method.agent != method_option.agent) {
            this.SetupShipmentTemplate();
          }
        }
        if (!method_options.length) {
          shipping.agent = '';
          shipping.method = '';
          this.ClearShipmentSetup();
          return focus_select('agent');
        }
        if (Object.keys(method).length) {
          if (!method_options.find(option => option.code == method.code)) {
            shipping.method = '';
            this.ClearShipmentSetup();
            if (method_options.length > 1) {
              focus_select('method');
            } else if (method_options.length == 1) {
              shipping.method = method_options[0];
              this.SetupShipmentTemplate();
            }
          }
        }
        if (method_options.length > 1 && !agent_select_focused) {
          focus_select('method');
        }
      },
      async HandleCreateShipmentCountry(role) {
        const shipment = this.modal.create.shipment;
        const setup = shipment.setup;
        const form = shipment.form.data;
        const tabs = shipment.tab.page;
        const country = shipment.country;
        const country_option = country[role];
        const shipping = shipment.shipping;
        const country_shipping = this.CloneObject(this.country.shipping);
        const shipping_option = this.CloneObject(this.shipping.option);
        const update = {};
        const update_country_shipping_agent_method = () => {
          update.sender_recipient = country.sender.code + '_' + country.recipient.code;
          update.country_shipping = country_shipping[update.sender_recipient] || {};
          update.country_shipping_keys = Object.keys(update.country_shipping);
          update.shipping_keys = Object.keys(country_shipping);
          update.shipping_sender_keys = update.shipping_keys.filter(key => key.split('_')[0] == country.sender.code);
          update.recipient_country_options = update.shipping_sender_keys.map(key => this.country.options.find(option => option.code == key.split('_')[1])).filter(e => e);
        }
        update_country_shipping_agent_method();
        const recipient_country_selected = update.recipient_country_options.length ? update.recipient_country_options.length == 1 ? update.recipient_country_options[0] : update.recipient_country_options.some(o => o.code == country.recipient.code) ? update.recipient_country_options.find(o => o.code == country.recipient.code) : '' : '';
        country.options.recipient = update.recipient_country_options || [];
        country.recipient = recipient_country_selected;
        update_country_shipping_agent_method();
        const shipping_agent = this.CloneObject(shipping.agent);
        const agent_options = shipping_option.agent.filter(option => update.country_shipping_keys.includes(option.code));
        const agent_selected = agent_options.length ? agent_options.length == 1 ? agent_options[0] : agent_options.some(o => o.code == shipping_agent.code) ? shipping_agent : '' : '';
        setup.option.agent = agent_options;
        shipping.agent = agent_selected;
        country.select[role].loading = true;
        shipment.form.disabled = true;
        await this.GetPostalCodeCity(country_option.code);
        country.select[role].loading = false;
        shipment.form.disabled = false;
        this.FilterShippingMethods(agent_options.length > 1);
        if (agent_options.length > 1 && !agent_selected) {
          let agent_select = this.$refs.shipping_agent;
          if (agent_select) setTimeout(() => {
            agent_select.$refs.search.focus();
          }, 100);
        }
        if (agent_selected) {
          this.SetupShipmentTemplate();
        }
        let country_role = role;
        let country_roles = ['sender', 'recipient'];
        let recipient_country = country.recipient.code;
        let return_label = /return/.test(shipping.method.code);
        /*
        let abroad_shipment = country.sender.code != country.recipient.code;
        if (return_label && abroad_shipment) {
          country_role = country_roles.find(role => role != country_role);
        }
        */
        if (return_label) {
          country_role = country_roles.find(role => role != country_role);
        }
        for (let tab of tabs) {
          for (let group of tab.group) {
            for (let field of (group || {}).field || []) {
              //let field_role = field.ref.split('_')[0];
              let fields = [].concat.apply([], tab.group.map(item => item.field));
              if (/country/.test(field.ref)) {
                form[field.name] = country_option[/id/.test(field.ref) ? 'id' : 'code'];
                field.value = country_option;
              }
              if (field.widget == 'bring_customer_number') {
                let bring_options = shipment.bring[recipient_country];
                if (bring_options) {
                  field.options = bring_options;
                  field.value = field.options[0];
                  form[field.name] = field.value.code;
                } else {
                  field.options = [];
                  field.value = '';
                  delete form[field.name];
                }
              }
              if (/service|addons/.test(field.widget)) {
                if (field.widget.includes('postnord')) {
                  let services = shipment.service.pdk.options || [];
                  let service_countries = shipment.service.pdk.countries || {};
                  let options = [];
                  field.loading = true;
                  if (field.widget.includes('service')) {
                    if (field.widget.includes('pickup')) {
                      options = ['P19DK', 'P19DKBP', 'P19DKDPD'];
                    }
                    if (field.widget.includes('private')) {
                      options = ['PDK17', 'PDK17BP', 'PDK17WORLD', 'PDK17DPD'];
                    }
                    options = options.filter(option => service_countries[option].includes(recipient_country));
                    field.options = services.filter(service => options.includes(service.code));
                    field.value = '';
                    delete form[field.name];
                    if (field.options.length == 1) {
                      field.value = field.options[0];
                      form[field.name] = field.value.code;
                      field.input(field.value);
                    }
                    let service_addons_field = fields.find(field => /addons/.test(field.widget));
                    if (field.value && field.value.code && service_addons_field && field.value.code == 'PDK17WORLD') {
                      service_addons_field.required = service_addons_field.options.some(option => option.code == 'ECONOMY');
                    }
                  }
                  if (/addons/.test(field.widget)) {
                    if (/return/.test(field.widget)) {
                      options = [['DK', 'SE', 'NO'].includes(recipient_country) ? 'P24DK' : 'P24DKDPD'];
                      field.options = services.find(service => options.includes(service.code))?.addons;
                    }
                  }
                  field.loading = false;
                }
              }
              if (/currency/.test(field.widget) && country_role == 'sender') {
                let country_currency = field.options.find(option => option.iso == country_option.code);
                if (country_currency) {
                  field.value = country_currency;
                  form[field.name] = country_currency.code;
                  let customs_declaration = fields.find(field => /customs_declaration/.test(field.widget));
                  if (customs_declaration) customs_declaration.list.currency = country_currency.code;
                }
              }
              if (/address_book/.test(field.widget)) {
                let shipment_country = shipment.country[country_role];
                let address_book_options = this.CloneObject(this.address.options);
                address_book_options = address_book_options.filter(option => option.res_country_id == shipment_country.id);
                if (address_book_options.length) {
                  field.options = address_book_options;
                  field.widget = 'address_book';
                } else {
                  field.widget = 'address_book_empty';
                  delete field.options;
                }
                let default_sender_address = this.CloneObject(this.address.sender.default);
                if (/empty/.test(field.widget)) {
                  this.SelectAddress(country_role);
                } else if (country_role == 'sender' && default_sender_address) {
                  this.SelectAddress(country_role, default_sender_address);
                }
              }
            }
          }
        }
        if (shipping.agent && shipping.method) {
          this.AutoFocusFirstEmptyRequiredField();
        }
      },
      HandleCreateShipmentInputField(e) {
        if (!e) return;
        const event = e.type;
        const field = e.target;
        const type = field.type;
        const shipment = this.modal.create.shipment;
        const form = shipment.form.data;
        const tabs = shipment.tab.page;
        let value = form[field.name];
        let form_value = !!value;
        let ref = '';
        for (let key in this.$refs) {
          let elm = this.$refs[key]?.[0];
          if (elm && elm.name == field.name) {
            ref = key; break;
          }
        }
        if (event == 'blur') {
          if (form_value) {
            if (/reference/.test(ref)) {
              value = value.replace(/^(\d)Æ|~(\d{10})$/i, '$1:$2');
            }
            if (/name|address|city/.test(ref)) {
              if (!/email/.test(ref)) {
                value = this.TitleCase(value);
              }
              if (/name/.test(ref)) {
                let field_found = null;
                for (let tab of tabs) {
                  if (field_found) break;
                  for (let group of tab.group) {
                    if (field_found) break;
                    for (let group_field of (group || {}).field || []) {
                      if (group_field.ref == ref) {
                        let fields = [].concat.apply([], tab.group.map(item => item.field));
                        //let last_name_field = fields.find(field => /last/.test(field.ref));
                        let att_field = fields.find(field => /att/.test(field.ref));
                        if (att_field && (this.$refs[att_field.ref] || []).length) {
                          let att_field_input = this.$refs[att_field.ref][0];
                          if (!att_field_input.value) {
                            att_field_input.value = value;
                            att_field.value = value;
                            form[att_field.name] = value;
                          }
                          if (att_field.hidden) {
                            delete form[att_field.name];
                            /*
                            let full_name = value;
                            if (last_name_field && (this.$refs[last_name_field.ref] || []).length) {
                              let last_name_field_input = this.$refs[last_name_field.ref][0];
                              full_name += ` ${last_name_field_input.value}`.replace(/\s+/g, ' ').trim();
                            }
                            att_field.value = full_name;
                            form[att_field.name] = full_name;
                            */
                          }
                        }
                        field_found = true;
                        break;
                      }
                    }
                  }
                }
              }
            }
            if (/postal|vat/.test(ref)) {
              value = String(value).toUpperCase();
            }
            if (type == 'number') {
              if (field.step == 1) {
                value = Math.round(value);
              } else {
                //value = Math.round(value * 10000) / 10000;
                value = Number(value).toFixed(2);
              }
              if (value < field.min) {
                value = Number(field.min);
              }
              if (value && field.max && value > field.max) {
                value = Number(field.max);
              }
            }
            if (type == 'tel') {
              value = String(value).replace(/[^\d()+-]/ig, '');
            }
            if (type == 'email') {
              value = String(value).toLowerCase().replace(/\s/g, '');
              let name = value.substring(0, value.lastIndexOf('@'));
              let domain = value.substring(value.lastIndexOf('@') + 1);
              if (!/^([\w_.-]+)$/.test(domain)) {
                console.log(Punycode.toUnicode(domain));
              }
              if (/xn--/.test(domain)) {
                value = name + '@' + Punycode.toUnicode(domain);
              }
              field.type = 'text';
              field.type = 'email';
            }
            if (/date/.test(type)) {
              if (value && field.min && value < field.min) {
                field.value = field.min;
                value = field.value;
              }
            }
            if (typeof value == 'string') {
              value = value.replace(/\s+/g, ' ').trim();
            }
            field.value = value;
            form[field.name] = value;
          } else {
            field.value = '';
            delete form[field.name];
            if (field.type == 'email') {
              field.type = 'text';
              field.type = 'email';
            }
          }
        }
      },
      HandleCustomCreateShipmentPhonePrefixInputField(e) {
        if (!e) return;
        const event = e.type;
        const input = e.target;
        const name = input.name;
        const shipment = this.modal.create.shipment;
        const form = shipment.form.data;
        let field = input.parentElement;
        let ref = field.dataset.ref;
        let field_found = false;
        for (let tab of shipment.tab.page) {
          if (field_found) break;
          for (let group of tab.group) {
            if (field_found) break;
            for (field of (group || {}).field || []) {
              if (field_found) break;
              if (field.ref == ref) {
                field_found = true;
                break;
              }
            }
          }
        }
        if (field_found && event == 'blur') {
          let value = String(input.value).replace(/\s/g, '');
          let prefix = field.selected ? field.selected.code : '';
          let prefix_regex = prefix ? new RegExp(prefix.replace('+', '\\+')) : '';
          let prefix_option = field.options.find(option => new RegExp(`^${option.code.replace('+', '\\+')}`).test(value));
          if (prefix_option) {
            prefix = prefix_option.code;
            prefix_regex = new RegExp(prefix.replace('+', '\\+'));
            field.selected = prefix_option;
          }
          value = String(value).replace(prefix_regex, '');
          value = value.replace(/(\s)?[^\d()+-]/ig, '');
          form[name] = value ? prefix + value : '';
          field.value = value || '';
          input.value = value || '';
        }
      },
      HandleCustomCreateShipmentDatetimeInputField(e) {
        if (!e) return;
        const event = e.type;
        const input = e.target;
        const type = input.type;
        const name = input.name;
        const field = input.parentElement;
        const form = this.modal.create.shipment.form.data;
        const types = Array.from(field.children).map(i => i.type);
        const data = () => {
          let dataset = this.CloneObject(field.dataset);
          for (let key in dataset) {
            if (!types.includes(key)) {
              delete dataset[key];
            }
          }
          return dataset;
        }
        let value = input.value;
        if (event == 'input') {
          field.dataset[type] = value;
          if (!field.dataset[type]) {
            delete field.dataset[type];
          }
          if (Object.keys(data()).length != types.length) {
            delete form[name];
          }
        }
        if (event == 'blur') {
          if (Object.keys(data()).length != types.length) {
            return delete form[name];
          }
          if (value && input.min && value < input.min) {
            field.dataset[type] = input.min;
            input.value = input.min;
          }
          form[name] = data().date + 'T' + data().time;
        }
      },
      ItemInput(event, item) {
        const input = event.target;
        const data = input.dataset || {};
        const field = 'list' in item ? item : null;
        let list_item = field ? item.list.item : item;
        let item_value = input.value;
        let key = data.key;
        if (key == 'country' && item_value) {
          list_item[key] = String(item_value).toUpperCase();
        }
        if (input.type == 'number') {
          item_value = Number(item_value);
          list_item[key] = item_value;
          let quantity = list_item.quantity || 0;
          let value = list_item.value || 0;
          let total = quantity * value;
          list_item.total = Number(total).toFixed(2);
        }
        console.log(this.CloneObject(item))
      },
      ItemBlur(event, item) {
        const input = event.target;
        const field = 'list' in item ? item : null;
        const data = input.closest('li').dataset || {};
        let list_item = field ? item.list.item : item;
        let value = list_item[input.dataset.key];
        let key = input.dataset.key;
        if (item.type == 'number') {
          value = Number(value);
          if (!/\./.test(input.step)) {
            value = Math.round(value);
          }
          list_item[key] = value;
          input.value = value;
        }
        if (input.list) { // Input has associated datalist
          const datalist = Array.from(input.list.options);
          value = datalist.some(o => o.value == value) ? value : '';
          list_item[key] = value;
          input.value = value;
        }
        if (input.type == 'text' && value) {
          list_item[key] = String(value).replace(/\s+/g, ' ').trim();
        }
        if (key == 'tariff' && value) {
          list_item[key] = String(value).replace(/\D+/g, '') || '';
        }
        if (!field && data.index && data.name) {
          const form = this.modal.create.shipment.form.data;
          const content_item = form[data.name][data.index];
          if (content_item) for (let item_key in content_item) {
            switch (item_key) {
              case 'copies' : key = 'quantity'; break;
              case 'sourceCountryCode': key = 'country'; break;
              case 'contents': key = 'content'; break;
              case 'statNo': key = 'tariff'; break;
              case 'netWeight': key = 'weight'; break;
              case 'value': key = 'value'; break;
            }
            if (item_key != 'valuesPerItem') {
              content_item[item_key] = list_item[key];
            }
          }
        }
      },
      async AddItem(field) {
        const refs = {}, item = {}, line = {};
        const form = this.modal.create.shipment.form.data;
        return await new Promise((resolve, reject) => {
          let invalid = null;
          for (let key in this.$refs) {
            if (/list-item/.test(key)) {
              refs[key] = this.$refs[key][0];
            }
          }
          for (let key in refs) {
            let value = refs[key]['value' || 'textContent'];
            if (/tariff/.test(key) && refs[key].pattern && value) {
              if (!new RegExp(refs[key].pattern).test(value)) {
                invalid = refs[key]; break;
              }
            }
            if (!value && !/total/.test(key)) {
              invalid = refs[key]; break;
            }
            key = key.split('_').slice(-1).pop();
            value = field.list.item[key];
            item[key] = value;
            if (key != 'total') {
              switch (key) {
                case 'quantity': key = 'copies'; break;
                case 'country': key = 'sourceCountryCode'; break;
                case 'content': key = 'contents'; break;
                case 'tariff': key = 'statNo'; break;
                case 'weight': key = 'netWeight'; break;
                case 'value': key = 'value'; break;
              }
              line[key] = value;
            }
          }
          if (invalid) {
            invalid.focus();
            return reject();
          }
          field.list.items.unshift(item);
          if (!form[field.name]) {
            form[field.name] = [];
          }
          line.valuesPerItem = true;
          form[field.name].unshift(line);
          field.list.item = {total: 0.00};
          resolve(true);
        }).catch(e => e);
      },
      async ValidateAddedItems(field = {}) {
        const items = field.list.items || [];
        let list_items = document.querySelectorAll(`[data-name=${field.name}]`); 
        return await new Promise((resolve, reject) => {
          if (!list_items.length) return reject();
          list_items = Array.from(list_items);
          let index = 0, item = null, missing = null;
          for (index; index < items.length; index++) {
            item = items[index];
            for (let key in item) {
              if (!item[key]) {
                missing = key;
                break;
              }
            }
            if (missing) {
              break;
            }
          }
          if (missing) {
            list_items[index].querySelectorAll('input').forEach(list_item => {
              if (list_item.dataset.key == missing) list_item.focus();
            });
            return reject();
          }
          resolve(true);
        }).catch(e => e);
      },
      HoverItem(event = {}) {
        const item = event.target;
        const type = event.type;
        if (type == 'mouseenter') {
          if (item) item.classList.add('hover');
        }
        if (type == 'mouseleave') {
          if (item) item.classList.remove('hover');
        }
      },
      RemoveItem(index, field) {
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, remove it',
          disapprove: 'No',
          message: 'Removes the current line.',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          const form = this.modal.create.shipment.form.data;
          field.list.items.splice(index, 1);
          form[field.name].splice(index, 1);
        });
      },
      async LoadQuickShopPrinterStatus() {
        let props = ['nodrop', 'loading'];
        for (let prop of props) this.quick[prop] = true;
        await this.SetQuickShopPrinterStatus();
        for (let prop of props) this.quick[prop] = false;
      },
      async QuickShopReturn(option) {
        if (!option) return;
        const company = this.CloneObject(this.quick.company) || {};
        const shop = this.CloneObject(this.quick.selected) || {};
        if (!shop.printnode_id) {
          this.quick.selected = '';
          return this.$eventHub.$emit('ShowMessages', {
            message: 'No label printer selected for the shop in the address book',
            type: 'error',
            hide: 2000
          });
        }
        const request = {
          agent_code: 'gls',
          shipping_method: 'shop_return',
          operation_code: 'print',
          body: {
            printer_id: shop.printnode_id,
            ref: '{{Auto}} ({{Return}})',
            title: '[RETURN LABEL] GLS Shop return',
            args: {
              customer_address: shop.address,
              customer_city: shop.city,
              customer_country_num: shop.res_country_id,
              customer_full_name: shop.name,
              customer_zip_code: shop.zipcode,
              date: null,
              ref: '',
              sender_address: company.address,
              sender_city: company.city,
              sender_email: company.email,
              sender_name: company.name,
              sender_phone_number: company.phone,
              sender_res_country_id: company.res_country_id,
              sender_zip_code: company.zipcode,
              weight: 249
            }
          }
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, print it',
          disapprove: 'No',
          message: 'Prints out a return label on the shop\'s label printer.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return this.quick.selected = '';
          await this.CreateCustomLabel(request);
          this.quick.selected = '';
        });        
      },
      PopupMouseEvent(e) {
        const event = e.type;
        const clicked = e.target;
        if (event == 'mousedown') {
          return this.popup.mousedown = clicked;
        }
        const mousedown = this.popup.mousedown;
        const popup = document.querySelector('.validate-popup');
        const cancel = popup.querySelector('a.cancel');
        if ([popup, cancel].includes(clicked) && clicked == mousedown) {
          if (this.popup.button.cancel.click) {
            this.popup.button.cancel.click();
          }
          this.popup.show = false;
          this.$eventHub.$off('ValidateModalStop');
        }
        this.popup.mousedown = null;
      },
      PopupConfirm() {
        this.$eventHub.$emit('ValidateModalStop', true);
        this.popup.show = false;
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      IsSuperUser() {
        return this.Check([/*0*/ 'admin']);
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      },
      AllowCompanySearch() {
        return this.IsSuperUser() && this.IsMainCompany();
      },
      async GetPrinterOptions() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetPrinterList().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(Tool.PrinterOptions(response.result, 'label'));
          }).catch(reject);
        }).catch(e => e);
      },
      async SetQuickShopPrinterStatus(options) {
        options = options || await this.GetPrinterOptions();
        for (let shop of this.quick.options) {
          if (shop.printnode_id) {
            let printer = options.find(option => option.id == shop.printnode_id);
            if (printer) shop.printnode_connected = printer.state == 'connected';
          }
        }
      },
      async RefreshPrinterOptions() {
        return await new Promise(async resolve => {
          this.printer.options = await this.GetPrinterOptions();
          if (this.printer.selected.id) {
            if (!this.printer.options.some(option => option.id == this.printer.selected.id)) {
              this.printer.selected = '';
            }
          }
          resolve(this.printer.options);
        });
      },
      async CanSearchParcelShops() {
        return await new Promise((resolve, reject) => {
          if ('parcel_shop_search' in this) {
            return resolve(this.parcel_shop_search);
          }
          BPA.api.HasGoogleMapsAPIKey().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            this.parcel_shop_search = response.result.has_google_maps;
            resolve(this.parcel_shop_search);
          }).catch(reject);
        }).catch(e => e);
      },
      ToggleVisibility(event = {}) {
        if (event.type == 'mousedown') {
          this.ToggleVisibility.mousedown = event.target;
        }
        if (event.type == 'mouseup') {
          if (this.ToggleVisibility.mousedown == event.target) {
            event.target && event.target.classList.toggle('visible');
          }
          this.ToggleVisibility.mousedown = null;
        }
      },
      ClickOpen(event) {
        if (!event) return;
        let row = {};
        let elm = event.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = ['lock', 'lock locked', 'name', 'comment-exclamation', 'tracking url', 'list-icon', 'actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (/selector/.test(elm.className)) return;
        if (elm.classList.contains('courier-icon')) {
          const parent = elm.closest('.trackingnumber');
          const tracking = parent.dataset.tracking;
          if (tracking && tracking != 'shop') return;
        }
        if (filter.some(x => new RegExp(x).test(elm.className))) {
          if (elm.className != 'name') return;
          if (elm.parentElement.classList.contains('note')) return;
        }
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          mousedown() && this.OpenLabel(row.dataset.id);
          mousedown(false);
        }
      },
      PageController(next_page) {
        let load_page;
        const page = {};
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (this.page.current != this.page.last) {
            this.page.current++;
            load_page = true;
            if (this.page.current == this.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (this.page.current > 1) {
            this.page.current--;
            load_page = true;
            if (this.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.QueryLabels();
        }
      },
      PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != this.page.current) {
            this.page.current = page.number;
            this.QueryLabels();
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .field.sm.has-value:not(.no-label) .field-input {
    padding-top: 10px;
  }

  .v-list-head-column,
  .v-list-row-column {
    &.quantity {
      width: 10%;
    }
    &.country {
      width: 10%;
    }
    &.content {
      width: 50%;
    }
    &.tariff {
      width: 16%;
    }
    &.weight {
      width: 14%;
    }
    &.value {
      width: 14%;
    }
    &.total {
      width: 16%;
    }
  }

  .v-list-row {
    .total {
      border-width: 0 1px;
      border-style: solid;
      justify-content: flex-end;
      border-color: rgba(60, 60, 60, 0.26);
      background-color: rgba(0, 0, 0, 0.026);
    }

    &:last-child {
      background: white;
      border-top: 1px solid rgba(60, 60, 60, 0.26);
      
      .v-list-row-column.total {
        border-width: 0 1px 1px 1px;
        border-radius: 0px 0px 4px 4px;
      }
    }
  }

  td.shipment-date {
    white-space: pre;
  }
</style>
